import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { UpdateProjectRelationship } from '@/interfaces/components/UpdateProjectRelationship';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import Client from '@/models/Client';
import ClientRepository from '@/repositories/ClientRepository';
import { UpdateClientAndContactPersonOptions } from '@/interfaces/components/projectNew/UpdateClientAndContactPersonOptions';

export default class ProjectAPI {
    public static async getAll() {
        let getAllProjects;
        try {
            getAllProjects = (await ApiController.get(ApiRoutes.projects.getAll)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return getAllProjects;
    }

    public static async getById(id: string, fetchData: string) {
        let project;
        let apiRoute = ApiRoutes.projects.getSingleWithAllLeadAndOfferData;

        switch (fetchData) {
            case 'lead':
                apiRoute = ApiRoutes.projects.getSingleForLeadTab;
                break;
            case 'next_step':
                apiRoute = ApiRoutes.projects.getForNextStep;
                break;
            case 'basic':
                apiRoute = ApiRoutes.projects.getSingleWithGeneralInfo;
                break;
            case 'leads':
                apiRoute = ApiRoutes.projects.getSingleWithLeads;
                break;
            case 'offers':
                apiRoute = ApiRoutes.projects.getSingleForOfferTabWithoutOfferItems;
                break;
            case 'orders':
                apiRoute = ApiRoutes.projects.getSingleWithOrders;
                break;
            case 'payments':
                apiRoute = ApiRoutes.projects.getSingleWithPaymentRecords;
                break;
        }

        try {
            project = (await ApiController.get(apiRoute(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(project);
    }
    public static async fetchDataForProjectView(id: string) {
        let project;
        try {
            project = (await ApiController.get(ApiRoutes.projects.fetchForProjectView(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(project);
    }
    /*public static async fetchDataForOverviewTab(id: string) {
        let project;

        try {
            project = await ApiController.get(ApiRoutes.projects.fetchForOverviewTab(id));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(project);
    }

    public static async fetchForLeadTab(id: string) {
        let project;

        try {
            project = await ApiController.get(ApiRoutes.projects.fetchForLeadTab(id));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(project);
    }

    public static async fetchForOfferTab(id: string) {
        let project;

        try {
            project = await ApiController.get(ApiRoutes.projects.fetchForOfferTab(id));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(project);
    }

    public static async fetchForPaymentsTab(id: string) {
        let project;

        try {
            project = await ApiController.get(ApiRoutes.projects.fetchForPaymentsTab(id));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(project);
    }*/

    public static async getLeadProjects() {
        let leadProjects;

        try {
            leadProjects = (await ApiController.get(ApiRoutes.projects.leadProjects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadProjects);
    }

    public static async getAdminProjects() {
        let adminProjects;

        try {
            adminProjects = (await ApiController.get(ApiRoutes.projects.adminProjects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(adminProjects);
    }

    public static async getOfferProjects() {
        let offerProjects;

        try {
            offerProjects = (await ApiController.get(ApiRoutes.projects.offerProjects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerProjects);
    }

    public static async getOfferAndRequestProjects() {
        let offerProjects;

        try {
            offerProjects = (await ApiController.get(ApiRoutes.projects.offerAndRequestProjects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerProjects);
    }

    public static async getOrderProjects() {
        let orderProjects;

        try {
            orderProjects = (await ApiController.get(ApiRoutes.projects.orderProjects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderProjects);
    }

    public static async getActivitiesProjects() {
        let activitiesProjects;

        try {
            activitiesProjects = (await ApiController.get(ApiRoutes.projects.activitiesProjects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(activitiesProjects);
    }

    public static async getChatProjects(query: string) {
        let chatProjects;

        try {
            chatProjects = (await ApiController.get(ApiRoutes.projects.chatProjects(query))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(chatProjects);
    }

    public static async getFilteredProjects(query: string, projectType: string, withoutIncludes = false) {
        let filteredProjects;
        let apiRoute = ApiRoutes.projects.filterLeadProjects;
        switch (projectType) {
            case ProjectTypes.Offer:
                apiRoute = ApiRoutes.projects.filterOfferProjects;
                break;
            case ProjectTypes.Order:
                apiRoute = ApiRoutes.projects.filterOrderProjects;
                break;
            case ProjectTypes.Admin:
                apiRoute = ApiRoutes.projects.filterAdminProjects;
                break;
            case ProjectTypes.Request:
                apiRoute = ApiRoutes.projects.filterRequestProjects;
                break;
            case ProjectTypes.All:
                apiRoute = ApiRoutes.projects.filterAllProjects;
        }

        if (withoutIncludes) {
            apiRoute = ApiRoutes.projects.getForTable;
        }

        try {
            filteredProjects = (await ApiController.get(apiRoute(query))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(filteredProjects);
    }

    public static async getByClient(clientId: string) {
        let clientProjects;

        try {
            clientProjects = (await ApiController.get(ApiRoutes.projects.getByClient(clientId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clientProjects);
    }

    public static async createNew(defaultClientId?: string | null) {
        const payload = {
            data: {
                type: 'projects',
                relationships: {},
            },
        };

        if (defaultClientId) {
            await Client.getById(defaultClientId);
            const contactPersons = ClientRepository.contactPersons(defaultClientId);

            if (contactPersons.length <= 0) {
                return Promise.reject(new Error('Contact person does not exist'));
            }
            // @ts-ignore
            payload.data.relationships.client = {
                data: {
                    type: 'clients',
                    id: defaultClientId,
                },
            };

            // @ts-ignore
            payload.data.relationships.contactPerson = {
                data: {
                    type: 'contact_persons',
                    id: contactPersons[0].id,
                },
            };
        }

        let newProject;
        try {
            newProject = (await ApiController.post(ApiRoutes.createNewProject, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newProject);
    }

    public static async updateExisting({
        projectId,
        clientId,
        assignedUserId,
        labelId,
        madeByUserId,
        connection,
        documentNameId,
        orderStatusId,
        contactPersonId,
        orderedBy,
    }: UpdateProjectRelationship) {
        const payload = {
            data: {
                type: 'projects',
                id: projectId,
                attributes: {},
                relationships: {},
            },
        };

        if (connection) {
            // @ts-ignore
            payload.data.attributes.connection = connection;
        }
        if (orderedBy !== null) {
            // @ts-ignore
            payload.data.attributes.orderedBy = orderedBy;
        }

        if (contactPersonId) {
            // @ts-ignore
            payload.data.relationships.contactPerson = {
                data: {
                    type: 'contact_persons',
                    id: contactPersonId,
                },
            };
        }

        if (clientId) {
            // @ts-ignore
            payload.data.relationships.client = {
                data: {
                    type: 'clients',
                    id: clientId,
                },
            };
        }

        if (documentNameId) {
            // @ts-ignore
            payload.data.relationships.documentName = {
                data: {
                    type: 'document_names',
                    id: documentNameId,
                },
            };
        }

        if (assignedUserId) {
            // @ts-ignore
            payload.data.relationships.assignedUser = {
                data: {
                    type: 'users',
                    id: assignedUserId,
                },
            };
        }

        if (labelId) {
            // @ts-ignore
            payload.data.relationships.label = {
                data: {
                    type: 'project_labels',
                    id: labelId,
                },
            };
        }
        if (madeByUserId) {
            // @ts-ignore
            payload.data.relationships.madeByUser = {
                data: {
                    type: 'users',
                    id: madeByUserId,
                },
            };
        }

        if (orderStatusId) {
            // @ts-ignore
            payload.data.relationships.orderStatus = {
                data: {
                    type: 'order_statuses',
                    id: orderStatusId,
                },
            };
        }

        let updatedProject;
        try {
            updatedProject = (await ApiController.patch(
                ApiRoutes.projects.updateExisting(projectId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedProject);
    }

    public static async updateRoltekHelp(projectId: string) {
        const payload = {
            data: {
                type: 'projects',
                id: projectId,
                attributes: {},
                relationships: {},
            },
        };

        // @ts-ignore
        payload.data.attributes.roltekHelp = true;

        let updatedProject;
        try {
            updatedProject = (await ApiController.patch(
                ApiRoutes.projects.updateRoltekAPP(projectId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedProject);
    }

    public static async updateClientAndContactPerson(requestOptions: UpdateClientAndContactPersonOptions) {
        const payload = {
            data: {
                type: 'projects',
                id: requestOptions.projectId,
                attributes: {},
                relationships: {
                    contactPerson: {
                        data: {
                            type: 'contact_persons',
                            id: requestOptions.contactPersonId,
                        },
                    },
                    client: {
                        data: {
                            type: 'clients',
                            id: requestOptions.clientId,
                        },
                    },
                },
            },
        };

        let updatedProject;
        try {
            updatedProject = await ApiController.patch(
                ApiRoutes.projects.updateClientAndContactPerson(requestOptions.projectId),
                payload
            );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedProject);
    }

    public static async updateProjectLabel(projectId: string, labelId: string) {
        const payload = {
            data: {
                type: 'projects',
                id: projectId,
                attributes: {},
                relationships: {
                    label: {
                        data: {
                            type: 'project_labels',
                            id: labelId,
                        },
                    },
                },
            },
        };

        let updatedProject;
        try {
            updatedProject = (await ApiController.patch(
                ApiRoutes.projects.updateExisting(projectId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedProject);
    }

    public static async updateBankAccount({ projectId, bankAccountId }: any) {
        const payload = {
            data: {
                type: 'projects',
                id: projectId,
                attributes: {},
                relationships: {
                    bankAccount: {
                        data: {
                            type: 'bank_accounts',
                            id: bankAccountId,
                        },
                    },
                },
            },
        };

        if (bankAccountId == null) {
            payload.data.relationships.bankAccount = {
                // @ts-ignore
                data: null,
            };
        }

        let updatedProject;
        try {
            updatedProject = (await ApiController.patch(
                ApiRoutes.projects.updateExisting(projectId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedProject);
    }

    public static async deleteExisting(projectId: string) {
        try {
            await ApiController.delete(ApiRoutes.projects.delete(projectId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
