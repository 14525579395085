
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppTable from '@/components/global/app-table/AppTable.vue';
import Project from '@/models/Project';
import {
    adminColumnData,
    allColumnData,
    createColumnData,
    leadsColumnData,
    offersColumnData,
    ordersColumnData,
    requestColumnData,
} from '@/helpers/TableHelper';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { FilterParameters } from '@/interfaces/components/FilterParameters';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import {
    checkIfLocalStorageParametersExist,
    constructUrlParams,
    fetchProjectFilterRelatedEntities,
} from '@/helpers/SearchHelper';
import { LocalStorageService } from '@/services/LocalStorageService';
import { ColumnSortedInfo } from '@/interfaces/ColumnSortedInfo';
import { UserRepository } from '@/repositories/UserRepository';
import CountriesDropdown from '@/components/global/CountriesDropdown.vue';
import MultipleSelect from '@/components/global/MultipleSelect.vue';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import TableFilters from '@/components/views/home/TableFilters.vue';
import { Getter } from 'vuex-class';
import User from '@/models/User';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { Column } from '@/interfaces/Column';
import ActivityUpdateModal from '@/components/global/ActivityUpdateModal.vue';
import ProjectRepository from '@/repositories/ProjectRepository';
import ProjectModel from '@/models/Project';
import { getAppropriateFilterOptions } from '@/helpers/NewProjectSearchBarHelper';
import { SearchEntityFilter } from '@/interfaces/SearchEntityFilter';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import ProjectSearchRepository from '@/repositories/ProjectSearchRepository';

@Component({
    name: 'ProjectsTableWrapper',
    components: {
        AppTable,
        CountriesDropdown,
        MultipleSelect,
        AutocompleteGroupInput,
        TableFilters,
        ActivityUpdateModal,
    },
})
export default class ProjectsTableWrapper extends Vue {
    @Getter('tableData/nextSteps')
    private nextSteps!: boolean;
    @Getter('tableData/projects')
    private projects!: boolean;

    @Prop({ required: true }) private entityType!: keyof typeof ProjectTypes;
    @Prop({ required: true }) private isSearchActive!: boolean;
    @Prop({ required: true }) private isDeleteMode!: boolean;
    @Prop({ required: false }) private searchMessage?: string;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ required: false, default: false }) private dontGoToProject?: boolean;

    private sortedInfo: ColumnSortedInfo | null = null;
    private sortField: string | null = null;
    private sortOrder: string | undefined | null = null;
    private areFiltersVisible = false;
    private project: Project | null = null;
    private columns: Array<Column<Project>> = [];
    private getFilterData: boolean = true;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get user() {
        return UserRepository.getCurrentUser();
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get isCurrentUserGroupRoltek() {
        return this.currentUserGroup && this.currentUserGroup.name === 'Roltek';
    }

    private get projectData() {
        return this.$store.getters['tableData/projects'];
    }

    private getColumnData() {
        if (this.isLoading) {
            return;
        }

        let columnOptions = leadsColumnData;
        switch (this.entityType) {
            case ProjectTypes.Order:
                columnOptions = ordersColumnData;
                break;
            case ProjectTypes.Offer:
                columnOptions = offersColumnData;
                break;
            case ProjectTypes.Admin:
                columnOptions = adminColumnData;
                break;
            case ProjectTypes.Request:
                columnOptions = requestColumnData;
                break;
            case ProjectTypes.All:
                columnOptions = allColumnData;
        }
        this.columns = createColumnData<Project>(
            columnOptions,
            this.sortedInfo,
            this.entityType,
            this.isCurrentUserGroupRoltek,
            this.onRowClick,
            !this.dontGoToProject,
            this.currentUserGroup?.id
        );
    }

    private updateSortedInfo(sorter: ColumnSortedInfo) {
        this.sortedInfo = sorter;
        this.sortField = sorter.columnKey;
        this.sortOrder = sorter.order;
        this.getColumnData();
    }

    private async onUpdateSorter(params: FilterParameters) {
        this.startLoading();

        this.updateSortedInfo({
            columnKey: params.sortColumnKey,
            order: params.sortOrder,
        });

        if (params.sortField != null) {
            this.sortField = params.sortField;
            this.sortOrder = params.sortOrder;
        }

        EventBus.$emit(EventBusEvents.runEntitySearch, { ...params, shouldOverrideSortParameters: false });
    }

    private async onMadeByUserSelect(assignedUserId: string, entityId: string) {
        this.startLoading();
        try {
            await Project.updateExisting({
                projectId: entityId,
                assignedUserId,
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.stopLoading();
            return;
        }
        this.$notification.success({
            message: this.$t('Komercijalist uspješno dodijeljen!') as string,
            description: this.$t('Projekt je maknut iz ove liste') as string,
        });

        this.stopLoading();
    }

    private async toggleFilters() {
        if (this.getFilterData) {
            this.getFilterData = false;
            this.startLoading();
            await fetchProjectFilterRelatedEntities(this.entityType);
            this.stopLoading();
        }

        this.areFiltersVisible = !this.areFiltersVisible;
    }

    private fireToggleFilters() {
        EventBus.$emit(EventBusEvents.toggleFilters);
    }

    private startLoading() {
        this.$emit('update:isLoading', true);
    }

    private stopLoading() {
        this.$emit('update:isLoading', false);
    }

    private onSearchFinished() {
        this.stopLoading();
        this.getColumnData();
    }

    private async onRowClick(record?: any) {
        if (this.dontGoToProject === false) {
            this.$store.commit('tableData/clearProjectNavigationSettings');

            if (checkIfLocalStorageParametersExist(this.user, this.entityType)) {
                const localStorageParameters = JSON.parse(
                    LocalStorageService.get(
                        // @ts-ignore
                        `${this.entityType}-${this.user.id}`
                    ) as string
                );

                const projectsFromStore = (this.$store.getters['tableData/projects'] as Project[])?.map((p) => p.id);

                this.$store.dispatch('tableData/updateProjectNavigationSettings', {
                    projectIds: projectsFromStore,
                    page: localStorageParameters.page,
                    userId: (this.user as User).id,
                    entityType: this.entityType,
                });

                return Promise.resolve();
            }

            return Promise.resolve();
        } else {
            if (this.entityType === ProjectTypes.All) {
                if (record) {
                    this.loadingOverlay.start();

                    await ProjectModel.getById(record?.id, 'next_step');
                    this.project = ProjectRepository.getProjectById(record?.id);

                    this.$emit('selected-project', this.project);
                    this.loadingOverlay.stop();
                }

                return Promise.resolve();
            }
        }
    }

    private async projectUpdated(args: any) {
        this.startLoading();
        try {
            if (args?.projectId && args?.labelId) {
                await Project.updateProjectLabel(args.projectId, args.labelId);
            }
            this.getColumnData();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        } finally {
            this.stopLoading();
        }
    }

    private resetProjectsInCaseOfRequests() {
        if (this.entityType === ProjectTypes.Request) {
            this.$store.commit('tableData/updateProjects', []);
        }
    }

    private created() {
        EventBus.$on(EventBusEvents.updateSortedInfo, this.updateSortedInfo);
        EventBus.$on(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$on(EventBusEvents.searchFinished, this.onSearchFinished);
        EventBus.$on(EventBusEvents.updateProject, this.projectUpdated);
        EventBus.$on(EventBusEvents.runEntitySearch, this.startLoading);
        EventBus.$on(EventBusEvents.runEntitySearchWithParameters, this.startLoading);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateSortedInfo, this.updateSortedInfo);
        EventBus.$off(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$off(EventBusEvents.searchFinished, this.onSearchFinished);
        EventBus.$off(EventBusEvents.updateProject, this.projectUpdated);
        EventBus.$off(EventBusEvents.runEntitySearch, this.startLoading);
        EventBus.$off(EventBusEvents.runEntitySearchWithParameters, this.startLoading);
    }

    @Watch('projectData', { deep: true })
    private onProjectDataChange() {
        if (this.sortOrder == null) {
            this.sortField = 'sortDate';
            this.sortOrder = 'descend';
        }
    }

    @Watch('entityType', { immediate: true })
    private async onEntityTypeChange() {
        this.sortField = null;
        this.sortOrder = null;
        this.resetProjectsInCaseOfRequests();
        this.startLoading();

        if (this.isSearchActive) {
            const filterOptions = getAppropriateFilterOptions({
                entityType: this.entityType,
                currentUser: this.user,
                shouldSetDefaultUser: true,
            }) as SearchEntityFilter[];
            if (checkIfLocalStorageParametersExist(this.user, this.entityType)) {
                const localStorageParameters = JSON.parse(
                    LocalStorageService.get(
                        // @ts-ignore
                        `${this.entityType}-${this.user.id}`
                    ) as string
                );

                localStorageParameters.availableFilters.forEach((filter: SearchEntityFilter) => {
                    const selectedFilterOption = filterOptions.find((filterOption: SearchEntityFilter) => {
                        return filterOption.filter === filter.filter;
                    });
                    // @ts-ignore
                    if (selectedFilterOption == null) {
                        return;
                    }
                    selectedFilterOption.value = filter.value;
                });
                this.sortOrder = localStorageParameters.sortOrder;
                this.sortField = localStorageParameters.sortField;

                this.updateSortedInfo({
                    columnKey: localStorageParameters.sortField,
                    order: localStorageParameters.sortOrder,
                });
                const searchParameters = {
                    isSearchActive: localStorageParameters.isSearchActive ?? true,
                    entityType: localStorageParameters.entityType ?? this.entityType,
                    availableFilters: filterOptions,
                    page: localStorageParameters?.page ?? 1,
                    searchQuery: localStorageParameters?.searchQuery ?? '',
                };
                EventBus.$emit(EventBusEvents.runEntitySearchWithParameters, searchParameters);
            } else {
                EventBus.$emit(EventBusEvents.runEntitySearch);
            }
        } else {
            try {
                this.getColumnData();
            } catch (e) {
                this.onSearchFinished();
                return;
            }
        }
    }
}
