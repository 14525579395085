import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';
import Client from '@/models/Client';
import ContactPerson from '@/models/ContactPerson';

const getters: GetterTree<ILocalState, {}> = {
    projectClientCreation(state): boolean {
        return state.projectClientCreation;
    },
    createdClient(state): Client | null {
        return state.createdClient;
    },
    createdContacts(state): ContactPerson[] {
        return state.createdContacts;
    },
    calendarPopup(state): boolean {
        return state.calendarPopup;
    },
    unreadNotificationsCount(state): number {
        return state.unreadNotifications;
    },
    sidebarCollapsed(state): boolean {
        return state.sidebarCollapsed;
    },
    innerWidth(state): number {
        return state.innerWidth;
    },
};

export default getters;
