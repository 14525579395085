
import { Component, Vue } from 'vue-property-decorator';
import { UserRepository } from '@/repositories/UserRepository';
import { Layout } from 'ant-design-vue';
import NewNavigationItems from '@/components/global/NewNavigationItems.vue';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import CustomContextMenu from '@/components/global/popup/CustomContextMenu.vue';
import Notification from '@/models/Notification';

@Component({
    name: 'NavigationTemplate',
    components: {
        NewNavigationItems,
        Layout,
        LayoutHeader: Layout.Header,
        LayoutContent: Layout.Content,
        CustomContextMenu,
    },
})
export default class NavigationTemplate extends Vue {
    private collapsed = true;
    private isVisible = true;
    private navWidth: string | null = null;
    private showMenu = false;
    private customMenuConfig: { id?: string; type?: string } = {};
    private setIntervalForNotifications: any = null;

    private get selectedNavbarKey(): string[] {
        return this.$route.meta && this.$route.meta.navigationKey ? [this.$route.meta.navigationKey] : [''];
    }

    private get shouldShowLogo() {
        const metaValue = this.$route.meta?.shouldShowNavSideBar;

        if (metaValue == null) {
            return true;
        }

        return metaValue;
    }

    private get user() {
        return UserRepository.getCurrentUser();
    }

    private get innerWidth() {
        return this.$store.getters['generic/innerWidth'];
    }

    private toggleCollapse(val: boolean) {
        if (!this.isTouchScreen) {
            this.collapsed = val;
        }
    }

    private handleResize() {
        this.$store.dispatch('generic/updateInnerWidth', window.innerWidth);

        this.navWidth = window.innerWidth < 992 ? '56px' : '88px';
    }

    private get isTouchScreen() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    private toggleVisibility(isPrinting: boolean) {
        this.isVisible = !isPrinting;
    }

    private toggleCustomMenu(data: { display: boolean; entity: { id?: string; coordinates: any } }) {
        this.showMenu = data.display;

        if (this.showMenu) {
            this.customMenuConfig = { ...data.entity };
        }
    }

    private async updateUnreadCount() {
        try {
            const unreadCount = await Notification.getUnreadCount();

            this.$store.dispatch('generic/updateUnreadNotifications', unreadCount.unread);
        } catch {
            this.$notification.error({
                message: this.$t('Error fetching notifications') as string,
                description: '',
            });
        }
    }

    private setNotificationsTimer() {
        this.setIntervalForNotifications = setInterval(() => {
            this.updateUnreadCount();
        }, 300000);
    }

    private setNotificationsFunctions() {
        this.updateUnreadCount();

        if (this.setIntervalForNotifications) {
            this.clearNotificationsTimer();
        } else {
            this.setNotificationsTimer();
        }
    }

    private clearNotificationsTimer() {
        clearInterval(this.setIntervalForNotifications);
    }

    private mounted() {
        EventBus.$on(EventBusEvents.print, this.toggleVisibility);
        this.$nextTick(() => {
            window.addEventListener('resize', this.handleResize);
        });

        this.handleResize();

        EventBus.$on(EventBusEvents.toggleCustomMenu, this.toggleCustomMenu);
        this.setNotificationsFunctions();
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.print, this.toggleVisibility);
        window.removeEventListener('resize', this.handleResize);

        EventBus.$off(EventBusEvents.toggleCustomMenu, this.toggleCustomMenu);
        this.clearNotificationsTimer();
    }
}
