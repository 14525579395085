import UploadedPdf from '@/models/UploadedPdf';
import Image from '@/models/Image';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';
import { FieldTypes } from '@/enums/components/CmsIndex/FieldTypes';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import ProductSystemDetails from '@/models/ProductSystemDetails';
import ProductSystemDetailsRepository from '@/repositories/ProductSystemDetailsRepository';
import { EntityConfiguration } from '@/interfaces/components/cms/EntityConfiguration';
import ChecklistField from '@/models/ChecklistField';
import ChecklistFieldRepository from '@/repositories/ChecklistFieldRepository';
import Label from '@/models/Label';
import LabelReporistory from '@/repositories/LabelReporistory';
import ProjectState from '@/models/ProjectState';
import ProjectStateRepository from '@/repositories/ProjectStateRepository';
import ProductSystemDetailsConfiguration from '@/models/ProductSystemDetailsConfiguration';
import CampaignType from '@/models/CampaignType';
import CampaignTypeRepository from '@/repositories/CampaignTypeRepository';
import Campaign from '@/models/Campaign';
import CampaignRepository from '@/repositories/CampaignReporistory';
import moment, { Moment } from 'moment';
import NextStepType from '@/models/NextStepType';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import CMSUser from '@/models/CMSUser';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import Locale from '@/models/Locale';
import LocaleRepository from '@/repositories/LocaleRepository';
import ClientType from '@/models/ClientType';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import Tax from '@/models/Tax';
import Country from '@/models/Country';
import CountryRepository from '@/repositories/CountryRepository';
import TaxRepository from '@/repositories/TaxRepository';
import DocumentName from '@/models/DocumentName';
import DocumentNameRepository from '@/repositories/DocumentNameRepository';
import UserGroup from '@/models/UserGroup';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import ClientRepository from '@/repositories/ClientRepository';
import OfferConfiguration from '@/models/OfferConfiguration';
import OfferConfigurationRepository from '@/repositories/OfferConfigurationRepository';
import PostCodeConnection from '@/models/PostCodeConnection';
import UserGroupConnection from '@/models/UserGroupConnection';
import UserGroupConnectionRepository from '@/repositories/UserGroupConnectionRepository';
import LeadType from '@/models/LeadType';
import LeadTypeRepository from '@/repositories/LeadTypeRepository';
import LeadOrigin from '@/models/LeadOrigin';
import LeadOriginRepository from '@/repositories/LeadOriginRepository';
import Currency from '@/models/Currency';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import ProductSystem from '@/models/ProductSystem';
import { ProductSystemRepository } from '@/repositories/ProductSystemRepository';
import OrderStatus from '@/models/OrderStatus';
import OrderStatusRepository from '@/repositories/OrderStatusRepository';
import OfferPdfProperties from '@/models/OfferPdfProperties';
import AwardImage from '@/models/AwardImage';
import HeaderImage from '@/models/HeaderImage';
import { UploadFile } from 'ant-design-vue/types/upload';
import { MultiplePropertiesValidationConfiguration } from '@/interfaces/components/MultiplePropertiesValidationConfiguration';
import { ActualValueFromEntitySettings } from '@/interfaces/components/cms/ActualValueFromEntitySettings';
import i18n from '@/i18n';
import IntroScreen from '@/models/IntroScreen';
import IntroScreenRepository from '@/repositories/IntroScreenRepository';
import IntroScreenItem from '@/models/IntroScreenItem';
import PaymentType from '@/models/PaymentType';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import BankAccount from '@/models/BankAccount';
import BankAccountRepository from '@/repositories/BankAccountRepository';
import TaxDetails from '@/models/TaxDetails';
import TaxDetailsRepository from '@/repositories/TaxDetailsRepository';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';
import ProjectLabelTemplateRepository from '@/repositories/ProjectLabelTemplateRepository';
import AutomaticPriceManipulation from '@/models/AutomaticPriceManipulation';
import AutomaticPriceManipulationRepository from '@/repositories/AutomaticPriceManipulationRepository';
import PriceManipulationType from '@/models/PriceManipulationType';
import PriceManipulationTypeRepository from '@/repositories/PriceManipulationTypeRepository';
import ProductCategory from '@/models/ProductCategory';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { UserRightsEnum } from '@/enums/global/UserRights';
import EmailTemplateProperty from '@/models/EmailTemplateProperty';
import EmailTemplatePropertyRepository from '@/repositories/EmailTemplatePropertyRepository';
import { OfferPriceType } from '@/enums/global/OfferPriceType';
import ProductGroup from '@/models/ProductGroup';
import ProductGroupRepository from '@/repositories/ProductGroupRepository';
import User from '@/models/User';
import { UserRepository } from '@/repositories/UserRepository';
import { UserRoles } from '@/enums/global/UserRoles';
import OrderConfiguration from '@/models/OrderConfiguration';
import OrderConfigurationRepository from '@/repositories/OrderConfigurationRepository';
import OrderPdfProperties from '@/models/OrderPdfProperties';
import FlaggedPdfValue from '@/models/FlaggedPdfValue';
import DropdownOption from '@/models/DropdownOption';
import DropdownOptionRepository from '@/repositories/DropdownOptionRepository';
import FlaggedPdfValueRepository from '@/repositories/FlaggedPdfValueRepository';
import ProductForm from '@/models/ProductForm';
import ProductSystemWizard from '@/models/ProductSystemWizard';
import WarrantyWarning from '@/models/WarrantyWarning';
import WarrantyWarningRepository from '@/repositories/WarrantyWarningsRepository';
import ProductVersion from '@/models/ProductVersion';
import ProductVersionRepository from '@/repositories/ProductVersionRepository';
import ProductMaterial from '@/models/ProductMaterial';
import ProductMaterialRepository from '@/repositories/ProductMaterialRepository';
import ProductFunctions from '@/models/ProductFunctions';
import ProductFunctionsRepository from '@/repositories/ProductFunctionsRepository';
import { ProductVersionTabs } from '@/enums/global/ProductVersionTabs';

export function convertFileToAntdFile(file: any) {
    let url = file.attachment;
    if (file.document) {
        url = file.document;
    }
    if (file.image) {
        url = file.image;
    }
    let fileName = url.split('/').splice(-1)[0];
    if (file.displayName) {
        fileName = file.displayName;
    }

    const antdFile = {
        uid: file.id,
        name: fileName,
        url,
        title: file.title,
        type: file.type,
        size: 1,
        thumbUrl: determineFileIcon(file.fileType),
    };

    if (file.position) {
        // @ts-ignore
        antdFile.position = file.position;
    }

    if (file.locale) {
        // @ts-ignore
        antdFile.locale = file.locale;
    }

    return antdFile;
}

export function getDefaultValue(editableProperty: EditableProperty, entity: any, propertyIndex?: number) {
    const editablePropertyType = editableProperty.type;
    let value = getDefaultValueBasedOnType(editablePropertyType);

    if (editableProperty.hasOwnProperty('overrideDefaultValue')) {
        value = editableProperty.overrideDefaultValue;
    }

    if (entity == null) {
        return value;
    }

    const entityProperty = getAppropriateParentEntity(editableProperty, entity);

    if (
        !verifyMultiplePropertiesExistence({
            editableProperty,
            entityProperty,
            propertyIndex,
        })
    ) {
        return value;
    }

    value = getValueFromEntity({
        editablePropertyType,
        editableProperty,
        entityProperty,
        propertyIndex,
        defaultValue: value,
    });
    return value;
}

export async function uploadFile(
    file: File,
    fileType: string,
    imageType?: string,
    position?: number,
    locale?: string | null
) {
    let uploadedFile;
    const formData = new FormData();
    const formDataPropertyName = fileType === 'multipleImages' ? 'image' : fileType;
    let imageCreateEndpoint = Image.createNew.bind(Image);

    if (imageType != null) {
        if (imageType === 'award') {
            imageCreateEndpoint = AwardImage.createNew.bind(AwardImage);
        }
        if (imageType === 'header') {
            imageCreateEndpoint = HeaderImage.createNew.bind(HeaderImage);
        }
        if (imageType === 'wizardImage') {
            imageCreateEndpoint = ProductSystemWizard.createImage.bind(ProductSystemWizard);
        }
    }

    if (position != null) {
        // @ts-ignore
        formData.append('position', position);
    }

    formData.append(formDataPropertyName, file);

    try {
        if (formDataPropertyName === 'document') {
            formData.append('locale', locale ? locale : '');
            uploadedFile = await UploadedPdf.createNew(formData);
        } else {
            uploadedFile = await imageCreateEndpoint(formData);
        }
    } catch (e) {
        return Promise.reject(e);
    }
    return Promise.resolve(uploadedFile);
}

export async function updateFile(file: any) {
    let uploadedFile;

    try {
        if (file.type === 'uploaded_pdfs') {
            uploadedFile = await UploadedPdf.updateExisting(file);
        } else {
            uploadedFile = await Image.updateExisting(file);
        }
    } catch (e) {
        return Promise.reject(e);
    }
    return Promise.resolve(uploadedFile);
}

export const tableConfig = [
    {
        entityName: ProductVersionTabs.PRODUCT_VERSIONS,
        entityFetchEndpoint: ProductVersion.getAll.bind(ProductVersion),
        getEntityRepositoryReference: ProductVersionRepository.getAll,
        columns: [
            {
                class: 'product-version-column',
                title: i18n.t('Product name'),
                dataIndex: 'name',
                key: 'name',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Checklist version'),
                dataIndex: 'checklistVersion',
                key: 'checklist-version',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.checklistVersion.localeCompare(b.checklistVersion),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Directory'),
                dataIndex: 'directory',
                key: 'directory',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.directory.localeCompare(b.directory),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Pricelist version'),
                dataIndex: 'priceListVersion',
                key: 'price-list-version',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.priceListVersion.localeCompare(b.priceListVersion),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Updated at'),
                dataIndex: 'updatedAt',
                key: 'updated-at',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
            },
        ],
        tableData: function () {
            return this.getEntityRepositoryReference().map((productVersion: Record<string, any>) => {
                return {
                    key: productVersion.$id,
                    name: productVersion.name,
                    checklistVersion: productVersion.productForm.checklistVersion,
                    directory: productVersion.productForm.directory,
                    priceListVersion: productVersion.productForm.priceListVersion,
                    updatedAt: formatDate(productVersion.productForm.updatedAt),
                };
            });
        },
    },
    {
        entityName: ProductVersionTabs.PRODUCT_MATERIALS,
        entityFetchEndpoint: ProductMaterial.getAll.bind(ProductMaterial),
        getEntityRepositoryReference: ProductMaterialRepository.getAll,
        columns: [
            {
                class: 'product-version-column',
                title: i18n.t('Article number'),
                dataIndex: 'name',
                key: 'name',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Commodity group'),
                dataIndex: 'commodityGroup',
                key: 'commodity-group',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.commodityGroup.localeCompare(b.commodityGroup),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Matchcode'),
                dataIndex: 'matchCode',
                key: 'match-code',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.matchCode.localeCompare(b.matchCode),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Price 1'),
                dataIndex: 'priceOne',
                key: 'prince-one',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => parseFloat(a.priceOne) - parseFloat(b.priceOne),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Price 2'),
                dataIndex: 'priceTwo',
                key: 'prince-two',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => parseFloat(a.priceTwo) - parseFloat(b.priceTwo),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Product group'),
                dataIndex: 'productGroup',
                key: 'price-list-version',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.productGroup.localeCompare(b.productGroup),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Updated at'),
                dataIndex: 'updatedAt',
                key: 'updated-at',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
            },
        ],
        tableData: function () {
            return this.getEntityRepositoryReference().map((productMaterial: Record<string, any>) => {
                return {
                    key: productMaterial.$id,
                    name: productMaterial.articleNumber,
                    commodityGroup: productMaterial.commodityGroup,
                    matchCode: productMaterial.matchcode,
                    priceOne: productMaterial?.price1?.[0]?.[0] ?? '',
                    priceTwo: productMaterial?.price2?.[0]?.[0] ?? '',
                    productGroup: productMaterial.productGroup,
                    updatedAt: formatDate(productMaterial.updatedAt),
                    images: productMaterial?.images ?? null,
                };
            });
        },
    },
    {
        entityName: ProductVersionTabs.PRODUCT_FUNCTIONS,
        entityFetchEndpoint: ProductFunctions.getAll.bind(ProductFunctions),
        getEntityRepositoryReference: ProductFunctionsRepository.getAll,
        columns: [
            {
                class: 'product-version-column',
                title: i18n.t('Name'),
                dataIndex: 'name',
                key: 'name',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Has error'),
                dataIndex: 'hasError',
                key: 'has-error',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.hasError.localeCompare(b.hasError),
            },
            {
                class: 'product-version-column',
                title: i18n.t('Updated at'),
                dataIndex: 'updatedAt',
                key: 'updated-at',
                defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
            },
        ],
        tableData: function () {
            return this.getEntityRepositoryReference().map((productFunction: Record<string, any>) => {
                return {
                    key: productFunction.$id,
                    name: productFunction.name,
                    hasError: String(productFunction.hasError),
                    updatedAt: formatDate(productFunction.updatedAt),
                };
            });
        },
    },
];

export function setEntityDefaultConfig(entityName: string) {
    const entityDefaultConfig: EntityConfiguration = {
        entityFetchEndpoint: null,
        additionalEntityFetchEndpoint: null,
        getEntityRepositoryReference: null,
        getAdditionalEntityRepositoryReference: null,
        entityCreateEndpoint: null,
        entityUpdateEndpoint: null,
        entitySegments: [],
    };
    switch (entityName) {
        case CmsEntityTypes.ProductDetails:
            entityDefaultConfig.entityFetchEndpoint = ProductSystemDetails.getById.bind(ProductSystemDetails);
            entityDefaultConfig.additionalEntityFetchEndpoint = ProductSystem.getById.bind(ProductSystem);
            entityDefaultConfig.getEntityRepositoryReference = ProductSystemDetailsRepository.getById;
            entityDefaultConfig.getAdditionalEntityRepositoryReference = ProductSystemRepository.getById;
            entityDefaultConfig.entityCreateEndpoint = ProductSystemDetails.createNew.bind(ProductSystemDetails);
            entityDefaultConfig.entityUpdateEndpoint = ProductSystemDetails.updateExisting.bind(ProductSystemDetails);
            entityDefaultConfig.nestedEntityUpdateEndpoint = ProductSystemDetailsConfiguration.updateExisting.bind(
                ProductSystemDetailsConfiguration
            );
            entityDefaultConfig.nestedEntityCreateEndpoint = ProductSystemDetailsConfiguration.createNew.bind(
                ProductSystemDetailsConfiguration
            );
            entityDefaultConfig.shouldHandleStartPage = true;
            entityDefaultConfig.shouldShowProductPicker = true;
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'title',
                            label: i18n.t('Title'),
                            type: 'input',
                        },
                        {
                            name: 'icon',
                            label: i18n.t('Icon'),
                            type: 'image',
                        },
                        {
                            name: 'pdfOfferImage',
                            label: i18n.t('Slika u PDF-u ponude'),
                            type: 'image',
                        },
                        {
                            name: 'catalogueImage',
                            label: i18n.t('Catalogue image'),
                            type: 'image',
                        },
                        {
                            name: 'description',
                            label: i18n.t('Description'),
                            type: 'input',
                        },
                        {
                            name: 'documents',
                            label: i18n.t('Documents'),
                            type: 'extendedFiles',
                            fileType: 'document',
                        },
                        {
                            name: 'gallery',
                            label: i18n.t('Gallery'),
                            type: 'extendedFiles',
                            fileType: 'image',
                        },
                        {
                            name: 'configurations',
                            label: i18n.t('Configurations'),
                            type: 'configurator',
                            fileType: 'image',
                            nestedPropertyName: 'image',
                            propertyLabelName: 'title',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.ProductImage:
            entityDefaultConfig.entityFetchEndpoint = ProductForm.getImageByFormId.bind(ProductForm);
            // entityDefaultConfig.getEntityRepositoryReference = ProductRepository.getById;
            // entityDefaultConfig.entityCreateEndpoint = Product.createNew.bind(Product);
            entityDefaultConfig.entityUpdateEndpoint = ProductForm.updateExisting.bind(ProductForm);

            entityDefaultConfig.shouldHandleStartPage = true;
            entityDefaultConfig.shouldShowProductPicker = true;
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        /*{
                            name: 'icon',
                            label: i18n.t('Ikona'),
                            type: 'image',
                        },*/
                        {
                            name: 'gallery',
                            label: i18n.t('Gallery'),
                            type: 'extendedFiles',
                            fileType: 'image',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.ChecklistFields:
            entityDefaultConfig.entityFetchEndpoint = ChecklistField.getById.bind(ChecklistField);
            entityDefaultConfig.getEntityRepositoryReference = ChecklistFieldRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = ChecklistField.updateExisting.bind(ChecklistField);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'description',
                            label: i18n.t('Description'),
                            type: 'richTextEditor',
                            shouldShowLegendInRichText: false,
                        },
                        {
                            name: 'image',
                            label: i18n.t('Image'),
                            type: 'image',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.Labels:
            entityDefaultConfig.entityFetchEndpoint = Label.getById.bind(Label);
            entityDefaultConfig.getEntityRepositoryReference = LabelReporistory.getById;
            entityDefaultConfig.entityUpdateEndpoint = Label.updateExisting.bind(Label);
            entityDefaultConfig.entityCreateEndpoint = Label.createNew.bind(Label);
            entityDefaultConfig.entityDeleteEndpoint = Label.deleteExisting.bind(Label);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'isDefault',
                            label: i18n.t('Set as default value?'),
                            type: 'switch',
                        },
                        {
                            name: 'color',
                            label: i18n.t('Color'),
                            type: 'colorPicker',
                        },
                        {
                            name: 'Project state',
                            label: i18n.t('Status projekta'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: ProjectState.getAll.bind(ProjectState),
                            getDropdownOptions: ProjectStateRepository.getAll,
                            isDisabledOnEditing: true,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.CampaignTypes:
            entityDefaultConfig.entityFetchEndpoint = CampaignType.getById.bind(CampaignType);
            entityDefaultConfig.getEntityRepositoryReference = CampaignTypeRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = CampaignType.updateExisting.bind(CampaignType);
            entityDefaultConfig.entityCreateEndpoint = CampaignType.createNew.bind(CampaignType);
            entityDefaultConfig.entityDeleteEndpoint = CampaignType.deleteExisting.bind(CampaignType);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.Campaigns:
            entityDefaultConfig.entityFetchEndpoint = Campaign.getById.bind(Campaign);
            entityDefaultConfig.getEntityRepositoryReference = CampaignRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = Campaign.updateExisting.bind(Campaign);
            entityDefaultConfig.entityCreateEndpoint = Campaign.createNew.bind(Campaign);
            entityDefaultConfig.entityDeleteEndpoint = Campaign.deleteExisting.bind(Campaign);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'startDate',
                            label: i18n.t('Start date'),
                            type: 'date',
                        },
                        {
                            name: 'endDate',
                            label: i18n.t('End date'),
                            type: 'date',
                        },
                        {
                            name: 'campaignType',
                            label: i18n.t('Campaign type'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: CampaignType.getAll.bind(CampaignType),
                            getDropdownOptions: CampaignTypeRepository.getAll,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.NextStepTypes:
            entityDefaultConfig.entityFetchEndpoint = NextStepType.getById.bind(NextStepType);
            entityDefaultConfig.getEntityRepositoryReference = NextStepTypeRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = NextStepType.updateExisting.bind(NextStepType);
            entityDefaultConfig.entityCreateEndpoint = NextStepType.createNew.bind(NextStepType);
            entityDefaultConfig.entityDeleteEndpoint = NextStepType.deleteExising.bind(NextStepType);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'color',
                            label: i18n.t('Color'),
                            type: 'colorPicker',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.Users:
            entityDefaultConfig.entityFetchEndpoint = CMSUser.getById.bind(CMSUser);
            entityDefaultConfig.getEntityRepositoryReference = CMSUserRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = CMSUser.updateExisting.bind(CMSUser);
            entityDefaultConfig.entityCreateEndpoint = CMSUser.createNew.bind(CMSUser);
            entityDefaultConfig.nestedEntityCreateEndpoint = UserGroupConnection.createNew.bind(UserGroupConnection);
            entityDefaultConfig.nestedEntityUpdateEndpoint =
                UserGroupConnection.updateExisting.bind(UserGroupConnection);
            entityDefaultConfig.nestedEntityDeleteEndpoint =
                UserGroupConnection.deleteExisting.bind(UserGroupConnection);
            entityDefaultConfig.entityCreateEndpoint = CMSUser.createNew.bind(CMSUser);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'email',
                            label: i18n.t('E-mail'),
                            type: 'input',
                            isDisabledOnEditing: true,
                        },
                        {
                            name: 'phoneNumber',
                            label: i18n.t('Phone number'),
                            type: 'input',
                        },
                        {
                            name: 'password',
                            label: i18n.t('Password'),
                            type: 'userPassword',
                            isHiddenOnEditing: true,
                            isDisabledOnEditing: true,
                        },
                        {
                            name: 'aliases',
                            label: i18n.t('Aliases'),
                            type: 'userAliases',
                        },
                        {
                            name: 'isSalesman',
                            label: i18n.t('Commercialist'),
                            type: 'checkbox',
                        },
                        {
                            name: 'isArchived',
                            label: i18n.t('Archived'),
                            type: 'checkbox',
                            isDisabledIfTrue: true,
                        },
                        {
                            name: 'locale',
                            label: i18n.t('Locale'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Locale.getAll.bind(Locale),
                            getDropdownOptions: LocaleRepository.getAll,
                        },
                        {
                            name: 'currency',
                            label: i18n.t('Currency'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Currency.getAll.bind(Currency),
                            getDropdownOptions: CurrencyRepository.getAll,
                        },
                        {
                            name: 'roles',
                            label: i18n.t('Role'),
                            type: 'staticMultiselect',
                            choices: [
                                {
                                    value: 'ROLE_USER',
                                    label: i18n.t('User'),
                                },
                                {
                                    value: 'ROLE_SUPER_ADMIN',
                                    label: i18n.t('Super admin'),
                                },
                            ],
                        },
                    ],
                },
                {
                    name: i18n.t('User groups'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'userGroupConnections',
                            label: i18n.t('User groups'),
                            type: 'userGroups',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.ClientTypes:
            entityDefaultConfig.entityFetchEndpoint = ClientType.getById.bind(ClientType);
            entityDefaultConfig.getEntityRepositoryReference = ClientTypeRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = ClientType.updateExisting.bind(ClientType);
            entityDefaultConfig.entityCreateEndpoint = ClientType.createNew.bind(ClientType);
            entityDefaultConfig.entityDeleteEndpoint = ClientType.deleteExisting.bind(ClientType);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'Color',
                            label: i18n.t('Color'),
                            type: 'colorPicker',
                        },
                        {
                            name: 'description',
                            label: i18n.t('Description'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.DocumentNames:
            entityDefaultConfig.entityFetchEndpoint = DocumentName.getById.bind(DocumentName);
            entityDefaultConfig.getEntityRepositoryReference = DocumentNameRepository.getById;
            entityDefaultConfig.entityCreateEndpoint = DocumentName.createNew.bind(DocumentName);
            entityDefaultConfig.entityUpdateEndpoint = DocumentName.updateExisting.bind(DocumentName);
            entityDefaultConfig.entityDeleteEndpoint = DocumentName.deleteExisting.bind(DocumentName);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'prefix',
                            label: i18n.t('Prefix'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.Taxes:
            entityDefaultConfig.entityFetchEndpoint = Tax.getById.bind(Tax);
            entityDefaultConfig.getEntityRepositoryReference = TaxRepository.getById;
            entityDefaultConfig.entityCreateEndpoint = Tax.createNew.bind(Tax);
            entityDefaultConfig.entityUpdateEndpoint = Tax.updateExisting.bind(Tax);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Country *'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'country',
                            label: i18n.t('Name of country'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Country.getAll.bind(Country),
                            getDropdownOptions: CountryRepository.getAll,
                            isDisabledOnEditing: true,
                        },
                    ],
                },
                {
                    name: i18n.t('Taxes'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'montage',
                            label: i18n.t('Physical customer with installation'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                        {
                            name: 'noMontage',
                            label: i18n.t('Physical customer without installation'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                        {
                            name: 'montageNonTax',
                            label: i18n.t('Taxpayer with installation'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                        {
                            name: 'noMontageNonTax',
                            label: i18n.t('Taxpayer without installation'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                        {
                            name: 'company',
                            label: i18n.t('Company with installation'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.TaxDetails:
            entityDefaultConfig.entityFetchEndpoint = TaxDetails.getById.bind(TaxDetails);
            entityDefaultConfig.getEntityRepositoryReference = TaxDetailsRepository.getById;
            entityDefaultConfig.entityCreateEndpoint = TaxDetails.createNew.bind(TaxDetails);
            entityDefaultConfig.entityUpdateEndpoint = TaxDetails.updateExisting.bind(TaxDetails);
            entityDefaultConfig.entityDeleteEndpoint = TaxDetails.deleteExisting.bind(TaxDetails);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Tax details'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'amount',
                            label: i18n.t('Amount'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                        {
                            name: 'description',
                            label: i18n.t('Description'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.UserGroups:
            entityDefaultConfig.entityFetchEndpoint = UserGroup.getById.bind(UserGroup);
            entityDefaultConfig.getEntityRepositoryReference = UserGroupRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = UserGroup.updateExisting.bind(UserGroup);
            entityDefaultConfig.entityCreateEndpoint = UserGroup.createNew.bind(UserGroup);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                        {
                            name: 'client',
                            label: i18n.t('Client'),
                            type: 'autocompleteGroup',
                            getAutocompleteOptions: ClientRepository.getAll,
                            getEntityName: ClientRepository.getNameById,
                            autocompleteType: 'clients',
                        },
                        {
                            name: 'locale',
                            label: i18n.t('Locale'),
                            type: 'dropdown',
                            isDisabledOnEditing: true,
                            fetchOptionsEndpoint: Locale.getAll.bind(Locale),
                            getDropdownOptions: LocaleRepository.getAll,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.PdfOfferOptions:
            entityDefaultConfig.entityFetchEndpoint = OfferConfiguration.getById.bind(OfferConfiguration);
            entityDefaultConfig.getEntityRepositoryReference = OfferConfigurationRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = OfferConfiguration.updateExisting.bind(OfferConfiguration);
            entityDefaultConfig.entityCreateEndpoint = OfferConfiguration.createNew.bind(OfferConfiguration);
            entityDefaultConfig.entityDuplicateEndpoint = OfferConfiguration.duplicateExisting.bind(OfferConfiguration);
            entityDefaultConfig.entityDeleteEndpoint = OfferConfiguration.archive.bind(OfferConfiguration);
            entityDefaultConfig.nestedEntityUpdateEndpoint = OfferPdfProperties.updateExisting.bind(OfferPdfProperties);
            entityDefaultConfig.nestedEntityCreateEndpoint = OfferPdfProperties.createNew.bind(OfferPdfProperties);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Name of PDF'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Name'),
                            type: 'input',
                        },
                    ],
                },
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'locale',
                            label: i18n.t('Locale'),
                            type: 'dropdown',
                            parentPropertyName: 'offerPdfProperties',
                            fetchOptionsEndpoint: Locale.getAll.bind(Locale),
                            getDropdownOptions: LocaleRepository.getAll,
                        },
                        {
                            name: 'printPrice',
                            label: i18n.t('Prikaži cijenu'),
                            type: 'radioGroup',
                            choices: generateSelectedPriceOptions(),
                            isRequired: true,
                        },
                        {
                            name: 'currency',
                            label: i18n.t('Valuta'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Currency.getAll.bind(Currency),
                            getDropdownOptions: CurrencyRepository.getAll,
                        },
                        {
                            name: 'logo',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Glavni logo'),
                            type: 'image',
                        },
                        {
                            name: 'headerImages',
                            parentPropertyName: 'offerPdfProperties',
                            imageType: 'header',
                            label: i18n.t('Manji logotipovi'),
                            idealImageSize: '85x75',
                            type: 'multipleImages',
                            numberOfItemsToBeShown: 4,
                        },
                        {
                            name: 'companyInfo',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Informacije o firmi'),
                            textEditorLabel: i18n.t('Informacije o firmi'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'introText',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Tekst uvoda u ponudu'),
                            textEditorLabel: i18n.t('Tekst uvoda u ponudu'),
                            type: 'richTextEditor',
                        },
                    ],
                },
                {
                    name: i18n.t('Odaberite podatke o proizvodima'),
                    indeterminateGroup: 'products',
                    editablePropertiesConfiguration: generateProductPDFOptions(),
                },
                {
                    name: i18n.t('Odaberite podatke vezane za cijenu'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'showDiscounts',
                            label: i18n.t('Prikaži popuste'),
                            type: 'radioGroup',
                            choices: generateDiscountPDFOptions(),
                        },
                        {
                            name: 'showPrice',
                            label: i18n.t('Odaberi cijene'),
                            type: 'radioGroup',
                            choices: generatePricePDFOptions(),
                        },
                        {
                            name: 'showUpn',
                            label: i18n.t('Prikaži UPN'),
                            type: 'checkbox',
                        },
                        {
                            name: 'bankAccount',
                            label: i18n.t('Bankovni račun'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: BankAccount.getAll.bind(BankAccount),
                            getDropdownOptions: BankAccountRepository.getAllWithFormattedName,
                        },
                    ],
                },
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'offerInformation',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Informacije o ponudi'),
                            textEditorLabel: i18n.t('Informacije o ponudi'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'paymentAndDelivery',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Plaćanje i dostava'),
                            textEditorLabel: i18n.t('Plaćanje i dostava'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'awardImages',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Nagrade'),
                            idealImageSize: '110x50',
                            type: 'multipleImages',
                            imageType: 'award',
                            numberOfItemsToBeShown: 3,
                        },
                        {
                            name: 'imageSegment',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Sekcija sa slikama'),
                            textEditorLabel: i18n.t('Sekcija sa slikama'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'richtext',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Sekcija'),
                            textEditorLabel: i18n.t('Sekcija'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'signature',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Potpis'),
                            type: 'input',
                        },
                        {
                            name: 'footerColumn',
                            parentPropertyName: 'offerPdfProperties',
                            label: i18n.t('Podnožje'),
                            type: 'multipleTextareas',
                            numberOfItemsToBeShown: 4,
                            shouldCreateMultiples: true,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.PdfOrderTemplatesOptions:
            entityDefaultConfig.entityFetchEndpoint = OrderConfiguration.getById.bind(OrderConfiguration);
            entityDefaultConfig.getEntityRepositoryReference = OrderConfigurationRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = OrderConfiguration.updateExisting.bind(OrderConfiguration);
            entityDefaultConfig.nestedEntityUpdateEndpoint = OrderPdfProperties.updateExisting.bind(OrderPdfProperties);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Naziv PDFa'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                            isDisabledOnEditing: true,
                        },
                    ],
                },
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'locale',
                            label: i18n.t('Jezik'),
                            type: 'dropdown',
                            isDisabledOnEditing: true,
                            parentPropertyName: 'orderPdfProperties',
                            fetchOptionsEndpoint: Locale.getAll.bind(Locale),
                            getDropdownOptions: LocaleRepository.getAll,
                        },
                        {
                            name: 'printPrice',
                            label: i18n.t('Prikaži cijenu'),
                            type: 'radioGroup',
                            choices: generateSelectedPriceOptions(),
                            isRequired: true,
                        },
                        {
                            name: 'currency',
                            label: i18n.t('Valuta'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Currency.getAll.bind(Currency),
                            getDropdownOptions: CurrencyRepository.getAll,
                        },
                        {
                            name: 'logo',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Glavni logo'),
                            type: 'image',
                        },
                        {
                            name: 'headerImages',
                            parentPropertyName: 'orderPdfProperties',
                            imageType: 'header',
                            label: i18n.t('Manji logotipovi'),
                            idealImageSize: '85x75',
                            type: 'multipleImages',
                            numberOfItemsToBeShown: 4,
                        },
                        {
                            name: 'companyInfo',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Informacije o firmi'),
                            textEditorLabel: i18n.t('Informacije o firmi'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'introText',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Tekst uvoda u ponudu'),
                            textEditorLabel: i18n.t('Tekst uvoda u ponudu'),
                            type: 'richTextEditor',
                        },
                    ],
                },
                {
                    name: i18n.t('Odaberite podatke o proizvodima'),
                    indeterminateGroup: 'products',
                    editablePropertiesConfiguration: generateProductPDFOptions(),
                },
                {
                    name: i18n.t('Odaberite podatke vezane za cijenu'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'showDiscounts',
                            label: i18n.t('Prikaži popuste'),
                            type: 'radioGroup',
                            choices: generateDiscountPDFOptions(),
                        },
                        {
                            name: 'showPrice',
                            label: i18n.t('Odaberi cijene'),
                            type: 'radioGroup',
                            choices: generatePricePDFOptions(),
                        },
                        {
                            name: 'showUpn',
                            label: i18n.t('Prikaži UPN'),
                            type: 'checkbox',
                        },
                        {
                            name: 'bankAccount',
                            label: i18n.t('Bankovni račun'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: BankAccount.getAll.bind(BankAccount),
                            getDropdownOptions: BankAccountRepository.getAllWithFormattedName,
                        },
                    ],
                },
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'orderInformation',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Informacije o ponudi'),
                            textEditorLabel: i18n.t('Informacije o ponudi'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'paymentAndDelivery',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Plaćanje i dostava'),
                            textEditorLabel: i18n.t('Plaćanje i dostava'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'awardImages',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Nagrade'),
                            idealImageSize: '110x50',
                            type: 'multipleImages',
                            imageType: 'award',
                            numberOfItemsToBeShown: 3,
                        },
                        {
                            name: 'imageSegment',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Sekcija sa slikama'),
                            textEditorLabel: i18n.t('Sekcija sa slikama'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'richtext',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Sekcija'),
                            textEditorLabel: i18n.t('Sekcija'),
                            type: 'richTextEditor',
                        },
                        {
                            name: 'signature',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Potpis'),
                            type: 'input',
                        },
                        {
                            name: 'footerColumn',
                            parentPropertyName: 'orderPdfProperties',
                            label: i18n.t('Podnožje'),
                            type: 'multipleTextareas',
                            numberOfItemsToBeShown: 4,
                            shouldCreateMultiples: true,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.PostCodeConnections:
            entityDefaultConfig.entityFetchEndpoint = CMSUser.getById.bind(CMSUser);
            entityDefaultConfig.getEntityRepositoryReference = CMSUserRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = CMSUser.updateExisting.bind(CMSUser);
            entityDefaultConfig.entityCreateEndpoint = CMSUser.createNew.bind(CMSUser);
            entityDefaultConfig.nestedEntityUpdateEndpoint = PostCodeConnection.updateExisting.bind(PostCodeConnection);
            entityDefaultConfig.nestedEntityCreateEndpoint = PostCodeConnection.createNew.bind(PostCodeConnection);
            entityDefaultConfig.nestedEntityDeleteEndpoint = PostCodeConnection.deleteExisting.bind(PostCodeConnection);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Korisnik'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'user',
                            label: i18n.t('Ime'),
                            type: 'autocompleteGroup',
                            getAutocompleteOptions: CMSUserRepository.getAll,
                            getEntityName: CMSUserRepository.getNameById,
                            autocompleteType: 'user',
                            isDisabledOnEditing: true,
                        },
                    ],
                },
                {
                    name: i18n.t('Poštanski brojevi'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'postCodeConnections',
                            label: i18n.t('Poštanski brojevi'),
                            type: 'numberRangeGroup',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.UserGroupConnections:
            entityDefaultConfig.entityFetchEndpoint = UserGroupConnection.getById.bind(UserGroupConnection);
            entityDefaultConfig.getEntityRepositoryReference = UserGroupConnectionRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = UserGroupConnection.updateExisting.bind(UserGroupConnection);
            entityDefaultConfig.entityCreateEndpoint = UserGroupConnection.createNew.bind(UserGroupConnection);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Korisnička prava'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'userGroup',
                            label: i18n.t('Korisnička grupa'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: UserGroup.getAll.bind(UserGroup),
                            getDropdownOptions: () => {
                                const currentUser = UserRepository.getCurrentUser() as User;

                                if (!currentUser.roles.includes(UserRoles.SUPER_ADMIN)) {
                                    return UserRepository.getCurrentUsersUserGroups();
                                }

                                return UserGroupRepository.getAll();
                            },
                            isDisabledOnEditing: true,
                            isRequired: true,
                        },
                    ],
                },
                {
                    name: i18n.t('Projekti'),
                    indeterminateGroup: 'projects',
                    editablePropertiesConfiguration: [
                        {
                            name: UserRightsEnum.SEE_INQUIRIES,
                            label: i18n.t('Vidi upite'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.VIEW_DISCOUNTS,
                            label: i18n.t('Vidi popuste'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: false,
                        },
                        {
                            name: UserRightsEnum.VIEW_RECOMMENDED_PRICE,
                            label: i18n.t('Vidi preporučenu cijenu'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_DISCOUNTS,
                            label: i18n.t('Uredi popuste'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: false,
                        },
                        {
                            name: UserRightsEnum.VIEW_PRICETYPE_AND_PRICELIST,
                            label: i18n.t('Vidi tip cijene'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: false,
                        },
                        {
                            name: UserRightsEnum.EDIT_PRICETYPE_AND_PRICELIST,
                            label: i18n.t('Uredi tip cijene'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: false,
                        },
                        {
                            name: UserRightsEnum.VIEW_LEAD_ASSIGN,
                            label: i18n.t('Vidi Admin karticu za dodjeljivanje upita'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.DELETE_PROJECTS,
                            label: i18n.t('Obriši projekte'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.VIEW_PURCHASE_PRICE_MANIPULATIONS,
                            label: i18n.t('Vidi promjene nabavne cijene'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                            shouldCmsUserBeAdmin: false,
                        },
                        {
                            name: UserRightsEnum.EDIT_PURCHASE_PRICE_MANIPULATIONS,
                            label: i18n.t('Uredi promjene nabavne cijene'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                            shouldCmsUserBeAdmin: false,
                        },
                        {
                            name: UserRightsEnum.VIEW_PURCHASE_PRICE,
                            label: i18n.t('Vidi prodajnu cijenu'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.VIEW_PAYMENT_RECORDS,
                            label: i18n.t('Vidi uplate'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_PAYMENT_RECORDS,
                            label: i18n.t('Uredi uplate'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.SEND_ORDER,
                            label: i18n.t('Slanje XML-a ponude'),
                            type: 'checkbox',
                            shouldCmsUserBeAdmin: false,
                        },
                        {
                            name: UserRightsEnum.ALLOW_OFFER_ERRORS,
                            label: i18n.t('Dopusti greške unutar ponude'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                            shouldCmsUserBeAdmin: false,
                        },
                        {
                            name: UserRightsEnum.B2B_CONTROL_PANEL,
                            label: i18n.t('Vidi B2B upravljačku ploču'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                            shouldCmsUserBeAdmin: false,
                        },
                        {
                            name: UserRightsEnum.VIEW_ALL_NEXT_STEPS,
                            label: i18n.t('Vidi aktivnosti'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_NEXT_STEPS,
                            label: i18n.t('Edit activities'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.DELETE_NEXT_STEPS,
                            label: i18n.t('Delete activities'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.VIEW_CHAT,
                            label: i18n.t('Vidi komentare'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.DELETE_OFFER,
                            label: i18n.t('Obriši ponudu'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.AGENT_USER_RIGHT,
                            label: i18n.t('Agent selection'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.PRINT_OFFER_SPECIFICATION,
                            label: i18n.t('Print full offer specification'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.VIEW_ROLTEK_MONTAGE,
                            label: i18n.t('View Roltek montage'),
                            type: 'checkbox',
                        },
                    ],
                },
                {
                    name: 'Klijenti',
                    indeterminateGroup: 'clients',
                    editablePropertiesConfiguration: [
                        {
                            name: UserRightsEnum.CREATE_CLIENT,
                            label: i18n.t('Stvori klijente'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_CLIENT,
                            label: i18n.t('Uredi klijente'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.DELETE_CLIENT,
                            label: i18n.t('Obriši klijente'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_CLIENT_DETAILED,
                            label: i18n.t('Uredi podatke o klijentu'),
                            type: 'checkbox',
                        },
                    ],
                },
                {
                    name: 'Korisnici',
                    indeterminateGroup: 'users',
                    editablePropertiesConfiguration: [
                        {
                            name: UserRightsEnum.VIEW_USER,
                            label: i18n.t('Vidi karticu o korisnicima'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CREATE_USER,
                            label: i18n.t('Stvori korisnike'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_USER,
                            label: i18n.t('Uredi korisnike'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.EDIT_USER_RIGHTS,
                            label: i18n.t('Uredi korisnička prava'),
                            type: 'checkbox',
                        },
                    ],
                },
                {
                    name: 'CMS',
                    indeterminateGroup: 'cms',
                    editablePropertiesConfiguration: [
                        {
                            name: UserRightsEnum.CMS_TRANSLATIONS,
                            label: i18n.t('Prijevodi'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_CONTENT,
                            label: i18n.t('Vidi karticu o sadržajima'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_INFO_BUTTONS,
                            label: i18n.t('Info gumbi'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_STATUSES,
                            label: i18n.t('Stanja'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_CAMPAIGNS,
                            label: i18n.t('Kampanje'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_CAMPAIGN_TYPES,
                            label: i18n.t('Tipovi kampanja'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_NEXT_STEP_TYPES,
                            label: i18n.t('Tipovi sljedećih koraka'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_CLIENT_TYPES,
                            label: i18n.t('Tipovi klijenata'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_POST_CODE_CONNECTIONS,
                            label: i18n.t('Poštanski brojevi'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_DOCUMENT_NAMES,
                            label: i18n.t('Vrste dokumenata'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_OFFER_CONFIGURATIONS,
                            label: i18n.t('Opcije PDF-a'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_TAXES,
                            label: i18n.t('Porezi'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_LEAD_TYPES,
                            label: i18n.t('Vrsta upita'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_LEAD_ORIGINS,
                            label: i18n.t('Izvor upita'),
                            type: 'checkbox',
                        },
                        {
                            name: UserRightsEnum.CMS_CURRENCIES,
                            label: i18n.t('Valute'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: 'CMS_EMAIL_TEMPLATE_PROPERTIES',
                            label: i18n.t('Email predlošci'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_ORDER_STATUSES,
                            label: i18n.t('Status narudžbe'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_AUTOMATIC_PRICE_MANIPULATIONS,
                            label: i18n.t('Automatske promjene cijena'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_PRODUCT_GROUPS,
                            label: i18n.t('Grupe proizvoda'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_CATALOG,
                            label: i18n.t('Katalog'),
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_FLAGGED_FLAGGED_PDF_VALUES,
                            label: 'Flagged PDF values',
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.CMS_VALID_WARRANTY,
                            label: 'Valid warranty warnings',
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                        {
                            name: UserRightsEnum.VIEW_PRODUCT_VERSIONS,
                            label: 'Import status',
                            type: 'checkbox',
                            isOnlyVisibleForRoltekGroup: true,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.LeadTypes:
            entityDefaultConfig.entityFetchEndpoint = LeadType.getById.bind(LeadType);
            entityDefaultConfig.getEntityRepositoryReference = LeadTypeRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = LeadType.updateExisting.bind(LeadType);
            entityDefaultConfig.entityCreateEndpoint = LeadType.createNew.bind(LeadType);
            entityDefaultConfig.entityDeleteEndpoint = LeadType.deleteExisting.bind(LeadType);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.LeadOrigins:
            entityDefaultConfig.entityFetchEndpoint = LeadOrigin.getById.bind(LeadOrigin);
            entityDefaultConfig.getEntityRepositoryReference = LeadOriginRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = LeadOrigin.updateExisting.bind(LeadOrigin);
            entityDefaultConfig.entityCreateEndpoint = LeadOrigin.createNew.bind(LeadOrigin);
            entityDefaultConfig.entityDeleteEndpoint = LeadOrigin.deleteExisting.bind(LeadOrigin);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.Currency:
            entityDefaultConfig.entityFetchEndpoint = Currency.getById.bind(Currency);
            entityDefaultConfig.getEntityRepositoryReference = CurrencyRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = Currency.updateExisting.bind(Currency);
            entityDefaultConfig.entityCreateEndpoint = Currency.createNew.bind(Currency);
            entityDefaultConfig.entityDeleteEndpoint = Currency.deleteExisting.bind(Currency);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                        {
                            name: 'symbol',
                            label: i18n.t('Simbol'),
                            type: 'input',
                        },
                        {
                            name: 'exchangeRate',
                            label: i18n.t('Devizni tečaj'),
                            type: 'inputNumber',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.OrderStatus:
            entityDefaultConfig.entityFetchEndpoint = OrderStatus.getById.bind(OrderStatus);
            entityDefaultConfig.getEntityRepositoryReference = OrderStatusRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = OrderStatus.updateExisting.bind(OrderStatus);
            entityDefaultConfig.entityCreateEndpoint = OrderStatus.createNew.bind(OrderStatus);
            entityDefaultConfig.entityDeleteEndpoint = OrderStatus.deleteExisting.bind(OrderStatus);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.IntroScreenItem:
            entityDefaultConfig.entityFetchEndpoint = IntroScreen.getById.bind(IntroScreen);
            entityDefaultConfig.getEntityRepositoryReference = IntroScreenRepository.getById;
            entityDefaultConfig.entityCreateEndpoint = IntroScreen.createNew.bind(IntroScreen);
            entityDefaultConfig.entityUpdateEndpoint = IntroScreen.updateExisting.bind(IntroScreen);
            entityDefaultConfig.nestedEntityUpdateEndpoint = IntroScreenItem.updateExisting.bind(IntroScreenItem);
            entityDefaultConfig.nestedEntityCreateEndpoint = IntroScreenItem.createNew.bind(IntroScreenItem);
            entityDefaultConfig.nestedEntityDeleteEndpoint = IntroScreenItem.deleteExisting.bind(IntroScreenItem);
            entityDefaultConfig.entitySegments = [
                {
                    name: i18n.t('Postavke početnog zaslona (do 6)'),
                    editablePropertiesConfiguration: [
                        {
                            name: 'introScreenItems',
                            label: i18n.t('Postavke početnog zaslona (do 6)'),
                            type: 'salesProcessForms',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.PaymentTypes:
            entityDefaultConfig.entityFetchEndpoint = PaymentType.getById.bind(PaymentType);
            entityDefaultConfig.getEntityRepositoryReference = PaymentTypeRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = PaymentType.updateExisting.bind(PaymentType);
            entityDefaultConfig.entityCreateEndpoint = PaymentType.createNew.bind(PaymentType);
            entityDefaultConfig.entityDeleteEndpoint = PaymentType.deleteExisting.bind(PaymentType);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'templateName',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                        {
                            name: 'isDefault',
                            label: i18n.t('Postavljen kao početna vrijednost?'),
                            type: 'switch',
                        },
                        {
                            name: 'expirationDate',
                            label: i18n.t('Datum isteka'),
                            type: 'date',
                            overrideDefaultValue: '',
                        },
                        {
                            name: 'advance',
                            label: i18n.t('Avans'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                        {
                            name: 'discount',
                            label: i18n.t('Popust'),
                            suffix: '%',
                            type: 'inputWithSuffix',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.BankAccount:
            entityDefaultConfig.entityFetchEndpoint = BankAccount.getById.bind(LeadType);
            entityDefaultConfig.getEntityRepositoryReference = BankAccountRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = BankAccount.updateExisting.bind(LeadType);
            entityDefaultConfig.entityCreateEndpoint = BankAccount.createNew.bind(LeadType);
            entityDefaultConfig.entityDeleteEndpoint = BankAccount.deleteExisting.bind(LeadType);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'bankName',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                    ],
                },
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'accountNumber',
                            label: i18n.t('IBAN'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.ProjectLabelTemplates:
            entityDefaultConfig.entityFetchEndpoint = ProjectLabelTemplate.getById.bind(ProjectLabelTemplate);
            entityDefaultConfig.getEntityRepositoryReference = ProjectLabelTemplateRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = ProjectLabelTemplate.updateExisting.bind(ProjectLabelTemplate);
            entityDefaultConfig.entityCreateEndpoint = ProjectLabelTemplate.createNew.bind(ProjectLabelTemplate);
            entityDefaultConfig.entityDeleteEndpoint = ProjectLabelTemplate.deleteExisting.bind(ProjectLabelTemplate);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                        {
                            name: 'locale',
                            label: i18n.t('Jezik'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Locale.getAll.bind(Locale),
                            getDropdownOptions: LocaleRepository.getAll,
                            isDisabledOnEditing: true,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.EmailTemplateProperties:
            entityDefaultConfig.entityFetchEndpoint = EmailTemplateProperty.getById.bind(EmailTemplateProperty);
            entityDefaultConfig.getEntityRepositoryReference = EmailTemplatePropertyRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = EmailTemplateProperty.updateExisting.bind(EmailTemplateProperty);
            entityDefaultConfig.entityCreateEndpoint = EmailTemplateProperty.createNew.bind(EmailTemplateProperty);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'locale',
                            label: i18n.t('Jezik'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: Locale.getAll.bind(Locale),
                            getDropdownOptions: LocaleRepository.getAll,
                            isDisabledOnEditing: true,
                        },
                        {
                            name: 'headerRichtext',
                            label: i18n.t('Tekst u zaglavlju'),
                            textEditorLabel: i18n.t('Tekst u zaglavlju'),
                            type: 'richTextEditor',
                            shouldShowLegendInRichText: false,
                            shouldUploadImagesAsAttachments: true,
                        },
                        {
                            name: 'footerRichtext',
                            label: i18n.t('Tekst u podnožju'),
                            textEditorLabel: i18n.t('Tekst u podnožju'),
                            type: 'richTextEditor',
                            shouldShowLegendInRichText: false,
                            shouldUploadImagesAsAttachments: true,
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.AutomaticPriceManipulations:
            entityDefaultConfig.entityFetchEndpoint =
                AutomaticPriceManipulation.getById.bind(AutomaticPriceManipulation);
            entityDefaultConfig.getEntityRepositoryReference = AutomaticPriceManipulationRepository.getById;
            entityDefaultConfig.entityCreateEndpoint =
                AutomaticPriceManipulation.createNew.bind(AutomaticPriceManipulation);
            entityDefaultConfig.entityUpdateEndpoint =
                AutomaticPriceManipulation.updateExisting.bind(AutomaticPriceManipulation);
            entityDefaultConfig.entityDeleteEndpoint =
                AutomaticPriceManipulation.deleteExisting.bind(AutomaticPriceManipulation);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'priceManipulationType',
                            label: i18n.t('Tip'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: PriceManipulationType.getAll.bind(PriceManipulationType),
                            getDropdownOptions: PriceManipulationTypeRepository.getAll,
                        },
                        {
                            name: 'productCategory',
                            label: i18n.t('Kategorija'),
                            type: 'dropdown',
                            overrideDefaultValue: {
                                id: '0',
                            },
                            fetchOptionsEndpoint: ProductCategory.getAllWithoutIcons.bind(ProductCategory),
                            getDropdownOptions: () => {
                                return [
                                    ...ProductCategoriesRepository.getOnlyNamesAndIds(),
                                    {
                                        id: '0',
                                        name: i18n.t('Sve'),
                                    },
                                ];
                            },
                        },
                        {
                            name: 'value',
                            label: i18n.t('Iznos'),
                            suffix: '%',
                            type: 'inputNumber',
                        },
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.ProductGroups:
            entityDefaultConfig.entityFetchEndpoint = ProductGroup.getById.bind(ProductGroup);
            entityDefaultConfig.getEntityRepositoryReference = ProductGroupRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = ProductGroup.updateExisting.bind(ProductGroup);
            entityDefaultConfig.entityCreateEndpoint = ProductGroup.createNew.bind(ProductGroup);
            entityDefaultConfig.entityDeleteEndpoint = ProductGroup.deleteExisting.bind(ProductGroup);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'name',
                            label: i18n.t('Naziv'),
                            type: 'input',
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.FlaggedPdfValues:
            entityDefaultConfig.entityCreateEndpoint = FlaggedPdfValue.createNew.bind(FlaggedPdfValue);
            entityDefaultConfig.getEntityRepositoryReference = FlaggedPdfValueRepository.getById;
            entityDefaultConfig.entityUpdateEndpoint = FlaggedPdfValue.updateExisting.bind(FlaggedPdfValue);
            entityDefaultConfig.entityDeleteEndpoint = FlaggedPdfValue.deleteExisting.bind(FlaggedPdfValue);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'value',
                            label: i18n.t('Vrijednost'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: DropdownOption.getAll.bind(DropdownOption),
                            getDropdownOptions: () => {
                                return DropdownOptionRepository.getAll().map((option) => ({
                                    id: option.id,
                                    name: option.value,
                                }));
                            },
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        case CmsEntityTypes.WarrantyWarnings:
            entityDefaultConfig.entityFetchEndpoint = WarrantyWarning.getById.bind(WarrantyWarning);
            entityDefaultConfig.getEntityRepositoryReference = WarrantyWarningRepository.getById;
            entityDefaultConfig.entityCreateEndpoint = WarrantyWarning.createNew.bind(WarrantyWarning);
            entityDefaultConfig.entityUpdateEndpoint = WarrantyWarning.updateExisting.bind(WarrantyWarning);
            entityDefaultConfig.entitySegments = [
                {
                    name: '',
                    editablePropertiesConfiguration: [
                        {
                            name: 'value',
                            label: i18n.t('Vrijednost'),
                            type: 'dropdown',
                            fetchOptionsEndpoint: WarrantyWarning.getAll.bind(WarrantyWarning, {
                                validWarranty: true,
                            }),
                            getDropdownOptions: () => {
                                return WarrantyWarningRepository.getAll().map((option) => {
                                    return {
                                        id: option.id,
                                        name: option.value,
                                        validWarranty: option.validWarranty,
                                        shouldRemoveValidWarranty: true,
                                    };
                                });
                            },
                        },
                    ],
                },
            ];
            return entityDefaultConfig;
        default:
            return null;
    }
}

export function formatDate(date: Moment | string | null | Date, dateFormat: string = 'DD.MM.YYYY HH:mm') {
    if (date === null) {
        return null;
    }

    const momentDate = moment(date);
    if (!momentDate.isValid()) {
        return null;
    }
    return momentDate.format(dateFormat);
}

export function formatTextLabel(fieldName: string, text: string) {
    let formattedTextLabel = text;
    if (fieldName === 'updatedAt') {
        const formattedDate = formatDate(text, 'DD.MM.YYYY HH:mm');
        const textValue = `${i18n.t('Zadnje uređeno na')} ${formattedDate}`;
        formattedTextLabel = textValue;
    }

    if (fieldName === 'createdAt') {
        const formattedDate = formatDate(text, 'DD.MM.YYYY HH:mm');
        const textValue = `${i18n.t('Napravljeno na')} ${formattedDate}`;
        formattedTextLabel = textValue;
    }

    return formattedTextLabel;
}

export function filterAndMapImagesWithPosition(uploadedImages: UploadFile[][], imageType: string) {
    const filteredUploadedImages = uploadedImages.filter((image) => image.length > 0);

    return filteredUploadedImages.map((image) => {
        return {
            type: imageType,
            id: image[0].uid,
        };
    });
}

function verifyMultiplePropertiesExistence({
    editableProperty,
    entityProperty,
    propertyIndex,
}: MultiplePropertiesValidationConfiguration) {
    if (propertyIndex) {
        if (entityProperty[`${editableProperty.name}${propertyIndex}`] == null) {
            return false;
        }
    } else if (
        entityProperty[editableProperty.name] == null &&
        editableProperty.type !== FieldTypes.AutocompleteGroup
    ) {
        return false;
    }
    return true;
}

function getAppropriateParentEntity(editableProperty: EditableProperty, entity: any) {
    return editableProperty.parentPropertyName != null && entity[editableProperty.parentPropertyName]
        ? entity[editableProperty.parentPropertyName]
        : entity;
}

function getDefaultValueBasedOnType(editablePropertyType: string) {
    let defaultValue: any = null;
    switch (editablePropertyType) {
        case FieldTypes.Input:
        case FieldTypes.InputWithSuffix:
        case FieldTypes.AutocompleteGroup:
        case FieldTypes.ColorPicker:
        case FieldTypes.RadioGroup:
        case FieldTypes.RichTextEditor:
        case FieldTypes.MultipleTextareas:
            defaultValue = '';
            break;
        case FieldTypes.InputNumber:
            defaultValue = 0;
            break;
        case FieldTypes.Switch:
        case FieldTypes.Checkbox:
            defaultValue = false;
            break;
        case FieldTypes.Date:
            defaultValue = moment(new Date());
            break;
        case FieldTypes.Image:
        case FieldTypes.Files:
        case FieldTypes.Gallery:
        case FieldTypes.ExtendedFiles:
        case FieldTypes.Configurator:
        case FieldTypes.NumberRangeGroup:
        case FieldTypes.UserGroups:
        case FieldTypes.StaticMultiselect:
        case FieldTypes.SalesProcessForms:
        case FieldTypes.UserAliases:
            defaultValue = [];
            break;
        case FieldTypes.MultipleImages:
            defaultValue = [[], [], [], []];
            break;
    }

    return defaultValue;
}

function getValueFromEntity({
    editableProperty,
    editablePropertyType,
    entityProperty,
    propertyIndex,
    defaultValue,
}: ActualValueFromEntitySettings) {
    let value: any = defaultValue;
    switch (editablePropertyType) {
        case FieldTypes.Switch:
        case FieldTypes.Checkbox:
        case FieldTypes.Input:
        case FieldTypes.InputWithSuffix:
        case FieldTypes.InputNumber:
        case FieldTypes.Dropdown:
        case FieldTypes.ColorPicker:
        case FieldTypes.RadioGroup:
        case FieldTypes.StaticMultiselect:
        case FieldTypes.RichTextEditor:
        case FieldTypes.UserAliases:
        case FieldTypes.SalesProcessForms:
            value = entityProperty[editableProperty.name];
            break;
        case FieldTypes.AutocompleteGroup:
            if (editableProperty.name === 'user') {
                value = entityProperty ? entityProperty.id : '';
                break;
            }
            value =
                entityProperty && entityProperty[editableProperty.name] ? entityProperty[editableProperty.name].id : '';
            break;
        case FieldTypes.Image:
            value = [convertFileToAntdFile(entityProperty[editableProperty.name])];
            break;
        case FieldTypes.Files:
        case FieldTypes.Gallery:
        case FieldTypes.ExtendedFiles:
            value = entityProperty[editableProperty.name].map((file: UploadedPdf & Image) => {
                return convertFileToAntdFile(file);
            });
            break;
        case FieldTypes.MultipleImages:
            entityProperty[editableProperty.name].forEach((file: UploadedPdf & Image & HeaderImage & AwardImage) => {
                const imageIndex = file.position;
                value[imageIndex] = [convertFileToAntdFile(file)];
            });

            break;
        case FieldTypes.Configurator:
            if (editableProperty.fileType !== 'image' && editableProperty.fileType !== 'document') {
                value = entityProperty[editableProperty.name].slice();
                break;
            }

            value = entityProperty[editableProperty.name].map((item: any) => {
                if (editableProperty.nestedPropertyName) {
                    item[editableProperty.nestedPropertyName] = [
                        convertFileToAntdFile(item[editableProperty.nestedPropertyName]),
                    ];
                }
                return item;
            });
            break;
        case FieldTypes.UserGroups:
            value = entityProperty[editableProperty.name].map((item: any, index: number) => {
                return { ...item, key: String(index) };
            });
            break;
        case FieldTypes.NumberRangeGroup:
            value = entityProperty[editableProperty.name].map((item: any, index: number) => {
                return { ...item, country: item.country ? item.country.id : '', key: String(index) };
            });
            break;
        case FieldTypes.Date:
            value = moment(entityProperty[editableProperty.name]);
            break;
        case FieldTypes.MultipleTextareas:
            value = entityProperty[`${editableProperty.name}${propertyIndex}`];
            break;
    }
    return value;
}

export function generateProductPDFOptions() {
    return [
        {
            name: 'showName',
            label: i18n.t('Prikaži ime proizvoda i dimenzije'),
            type: 'checkbox',
        },
        {
            name: 'showProductDetails',
            label: i18n.t('Prikaži detalje o proizvodima (ProductDetails tag)'),
            type: 'checkbox',
        },
        {
            name: 'showPricelists',
            label: i18n.t('Prikaži cjenike'),
            type: 'checkbox',
        },
        {
            name: 'showImages',
            label: i18n.t('Prikaži slike'),
            type: 'checkbox',
        },
        {
            name: 'showDifferences',
            label: i18n.t('Prikaži samo razlike'),
            type: 'checkbox',
        },
        {
            name: 'showProductImageOnRight',
            label: i18n.t('Prikaži slike zdesna'),
            type: 'checkbox',
        },
        {
            name: 'showImageOnce',
            label: i18n.t('Prikaži sliku proizvoda samo jednom'),
            type: 'checkbox',
        },
    ];
}

export function generateDiscountPDFOptions() {
    return [
        {
            value: 'DISCOUNT_NONE',
            label: i18n.t('Niti jedan'),
        },
        {
            value: 'DISCOUNT_B2B',
            label: i18n.t('B2B popusti'),
        },
        {
            value: 'DISCOUNT_B2C',
            label: i18n.t('B2C popusti'),
        },
        {
            value: 'DISCOUNT_B2B_B2C',
            label: i18n.t('B2B i B2C popusti'),
        },
    ];
}

export function generateSelectedPriceOptions() {
    return [
        {
            value: OfferPriceType.SELLING_PRICE,
            label: i18n.t('Prodajna cijena'),
        },
        {
            value: OfferPriceType.PURCHASE_PRICE,
            label: i18n.t('Kupovna cijena'),
        },
        {
            value: OfferPriceType.RECOMMENDED_PRICE,
            label: i18n.t('Preporučena cijena'),
        },
    ];
}

export function generatePricePDFOptions() {
    return [
        {
            value: 'PRICE_PRODUCT',
            label: i18n.t('Svaki proizvod'),
        },
        {
            value: 'PRICE_PRODUCT_GROUP',
            label: i18n.t('Svaka grupa proizvoda'),
        },
        {
            value: 'PRICE_FINAL_PRICE',
            label: i18n.t('Krajnja cijena'),
        },
        {
            value: 'PRICE_NO_PRICE',
            label: i18n.t('Bez cijene'),
        },
    ];
}

/**
 * Returns the globally defined file name to be used as an icon in the upload list
 * @param fileType - The type of the file
 * @return The path to the icon file
 */
export function determineFileIcon(fileType: string) {
    switch (fileType) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            return '/FileImage.svg';
        case 'pdf':
            return '/FilePdf.svg';
        default:
            return '/FileText.svg';
    }
}
