import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

const actions: ActionTree<ILocalState, {}> = {
    async getProjectInfo({ commit, getters }, projectId: number) {
        let info;
        try {
            info = (await ApiController.get(ApiRoutes.projects.getProjectInfo(projectId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(info);
    },

    updateUnreadNotifications({ commit }, notificationsCount) {
        commit('updateUnreadNotifications', notificationsCount);
    },

    updateSidebarState({ commit }, sidebarCollapsed: boolean) {
        commit('updateSidebarState', sidebarCollapsed);
    },
    updateInnerWidth({ commit }, innerWidth: number) {
        commit('updateInnerWidth', innerWidth);
    },
};

export default actions;
