import { FieldNumberValue } from '@/interfaces/general/FieldNumberValue';
import moment from 'moment';

export function validateAndFormatFloat(value: string, minValue: number, maxValue: number): string {
    const minValueActual = (minValue + 1).toString();
    const maxValueActual = (maxValue - 1).toString();
    const regex = /^-?\d*\.?\d*$/;

    if (value == null || value === '') {
        return minValueActual;
    }
    const regexExecArray: null | RegExpExecArray = regex.exec(value);

    if (regexExecArray == null) {
        return minValueActual;
    }

    let returnValue = regexExecArray[0];

    if (regexExecArray[0] == null || regexExecArray[0] === '.') {
        returnValue = minValueActual;
    }
    if (Number(regexExecArray[0]) >= maxValue) {
        returnValue = maxValueActual;
    }

    return returnValue;
}

export function validateAndFormatInteger(value: FieldNumberValue, minValue: number, maxValue: number) {
    let convertedNumber = Math.floor(Number(value));

    if (isNaN(convertedNumber) || convertedNumber <= minValue) {
        convertedNumber = minValue;
    }

    if (convertedNumber >= maxValue) {
        convertedNumber = maxValue;
    }

    return convertedNumber;
}

export function formatCurrency(
    number: null | number | undefined | string,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 6
) {
    const options = { minimumFractionDigits, maximumFractionDigits };
    let formattedNumber;

    if (!number || number === '-') {
        return '';
    }

    if (number === '0' || number === 0) {
        return String(number);
    }

    if (typeof number === 'string') {
        const formattedString = number.replace('.', '').replace(',', '.');
        formattedNumber = new Intl.NumberFormat('sl', options).format(parseFloat(formattedString));
    } else {
        formattedNumber = new Intl.NumberFormat('sl', options).format(number);
    }

    return formattedNumber;
}

function isDecimal(number: number): boolean {
    return !Number.isInteger(number);
}

function isDateValid(date: string) {
    // Define the array of date formats to check
    const formatsToCheck = [
        moment.ISO_8601, // ISO formats
        'MM-DD-YYYY',
        'DD-MM-YYYY',
        'YYYY-MM-DD',
        'YYYY.MM.DD',
        'MM.DD.YYYY',
        'DD.MM.YYYY',
        'YYYY/MM/DD',
        'DD/MM/YYYY',
        'MM/DD/YYYY',
    ];

    for (let format of formatsToCheck) {
        if (moment(date, format, true).isValid()) {
            return { isValid: true, format };
        }
    }

    return { isValid: false };
}

export function formatValueForDashboard(number: number | string, isPrice = false) {
    const parsedNumber = Number(number);

    const minimumFractionDigits = isDecimal(parsedNumber) || isPrice ? 2 : 0;

    if (!isNaN(parsedNumber)) {
        return new Intl.NumberFormat('sl', {
            maximumFractionDigits: 2,
            minimumFractionDigits: minimumFractionDigits,
            useGrouping: true,
        }).format(parsedNumber);
    }

    const isValidDate = isDateValid(String(number));

    if (isValidDate.isValid) {
        return moment(String(number), isValidDate.format).format('DD.MM.YYYY');
    }

    return number;
}
