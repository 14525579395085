import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class NotificationSettingsAPI {
    public static async getAll() {
        let notifications;

        try {
            notifications = (await ApiController.get(ApiRoutes.notification.getSettings)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(notifications);
    }

    public static async updateSettings(payload: any) {
        let notifications;

        try {
            notifications = (await ApiController.patch(ApiRoutes.notification.setSettings, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(notifications);
    }
}
