import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import { formatDate } from '@/helpers/CmsIndexHelper';

export default class CampaignAPI {
    public static async getAll() {
        let campaigns;

        try {
            campaigns = (await ApiController.get(ApiRoutes.campaigns.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaigns);
    }

    public static async getById(campaignId: string) {
        let campaigns;

        try {
            campaigns = (await ApiController.get(ApiRoutes.campaigns.getById(campaignId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaigns);
    }

    public static async delete(campaignId: string) {
        try {
            await ApiController.delete(ApiRoutes.campaigns.delete(campaignId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(campaignId: string, campaignInfo: AvailableLabelFields) {
        let campaign;

        const request = {
            data: {
                type: 'campaigns',
                id: campaignId,
                attributes: {
                    name: campaignInfo.name,
                    startDate: formatDate(campaignInfo.startDate, 'YYYY-MM-DD HH:mm:ss'),
                    endDate: formatDate(campaignInfo.endDate, 'YYYY-MM-DD HH:mm:ss'),
                },
                relationships: {},
            },
        };

        const relationships: TJsonApiRelationships = {
            campaignType: {
                // @ts-ignore
                data: null,
            },
        };

        if (campaignInfo.campaignType) {
            relationships.campaignType = {
                data: {
                    type: 'campaign_types',
                    id: campaignInfo.campaignType.id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            campaign = (await ApiController.patch(ApiRoutes.campaigns.update(campaignId), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaign);
    }

    public static async create(campaignInfo: AvailableLabelFields) {
        let campaign;

        const request = {
            data: {
                type: 'campaigns',
                attributes: {
                    name: campaignInfo.name,
                    startDate: formatDate(campaignInfo.startDate, 'YYYY-MM-DD HH:mm:ss'),
                    endDate: formatDate(campaignInfo.endDate, 'YYYY-MM-DD HH:mm:ss'),
                },
                relationships: {},
            },
        };

        const relationships: TJsonApiRelationships = {
            campaignType: {
                // @ts-ignore
                data: null,
            },
        };

        if (campaignInfo.campaignType) {
            relationships.campaignType = {
                data: {
                    type: 'campaign_types',
                    id: campaignInfo.campaignType.id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            campaign = (await ApiController.post(ApiRoutes.campaigns.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaign);
    }
}
