import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';
import Client from '@/models/Client';
import ContactPerson from '@/models/ContactPerson';

const mutations: MutationTree<ILocalState> = {
    setProjectClientCreation(state, flag: boolean) {
        state.projectClientCreation = flag;
    },
    setCreatedClient(state, client: Client | null) {
        state.createdClient = client;
    },
    addCreatedContact(state, contact: ContactPerson) {
        state.createdContacts.push(contact);
    },
    emptyCreatedContacts(state) {
        state.createdContacts = [];
    },
    setCalendarPopup(state, flag: boolean) {
        state.calendarPopup = flag;
    },
    updateUnreadNotifications(state, unreadCount) {
        state.unreadNotifications = unreadCount;
    },
    updateSidebarState(state, sidebarCollapsed: boolean) {
        state.sidebarCollapsed = sidebarCollapsed;
    },
    updateInnerWidth(state, innerWidth: number) {
        state.innerWidth = innerWidth;
    },
};

export default mutations;
