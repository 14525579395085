import i18n from '@/i18n';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import router from '@/router';
import { Route } from 'vue-router/types/router';
import Label from '@/models/Label';
import OrderStatus from '@/models/OrderStatus';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import OfferRepository from '@/repositories/OfferRepository';
import Offer from '@/models/Offer';
import { UserRepository } from '@/repositories/UserRepository';
import { formatCurrency } from '@/helpers/NumberFormatter';
import { OfferStates } from '@/enums/global/OfferStates';
import Client from '@/models/Client';
import Project from '@/models/Project';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';
import { formatAddress } from '@/helpers/project/AddressHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Router from '@/router';
import { sortArrayByGivenPropertyName } from '@/helpers/TableHelper';

export function getProjectTabsConfiguration(showDemands: boolean = true, showOrders: boolean = true) {
    const arr = [
        {
            component: () => import('@/components/views/NewProjectView/ProjectTabOverview.vue'),
            label: i18n.t('Pregled'),
            key: ProjectTabValues.Basic,
        },
        {
            component: () => import('@/components/views/NewProjectView/ProjectTabLead.vue'),
            label: i18n.t('Upit'),
            key: ProjectTabValues.Lead,
            show: false,
        },
        {
            component: () => import('@/components/views/NewProjectView/ProjectTabOffers.vue'),
            label: i18n.t('Ponude'),
            key: ProjectTabValues.Products,
        },
        {
            component: () => import('@/components/views/NewProjectView/ProjectTabOrders.vue'),
            label: i18n.t('Orders'),
            key: ProjectTabValues.Orders,
        },
        {
            component: () => import('@/components/views/NewProjectView/ProjectTabPayments.vue'),
            label: i18n.t('Uplate'),
            key: ProjectTabValues.Payments,
        },
    ];

    if (!showDemands) {
        arr.splice(1, 1);
    }

    if (!showOrders && !showDemands) {
        arr.splice(2, 1);
    }

    if (!showOrders && showDemands) {
        arr.splice(3, 1);
    }

    return arr;
}

/**
 * Updates the URL with the newly given active key and scrolls to the top of the page.
 * Happens only if the active key is different from the given one and if the given key is in the enum.
 * @param route - The current route
 * @param activeKey - The currently active key
 * @param key - The given key
 */
export async function onKeyChange(
    route: Route,
    activeKey: ProjectTabValues | null,
    key?: string | Array<string | null>
) {
    const keyAsProjectTabValue = Number(key);

    if (!(keyAsProjectTabValue in ProjectTabValues) || keyAsProjectTabValue === activeKey) {
        return Promise.reject(new Error('Given key is either not in project tab values or is equal to active key'));
    }

    const url = route.path + '?initialTab=' + key + '&ug=' + route.query.ug;

    history.pushState({}, '', url);

    window.scrollTo(0, 0);

    return Promise.resolve();
}

/**
 * Generates the current active status for the project
 * @param label - The current label on the project, if it exists
 * @param orderStatus - The current order status on the project, if it exists
 * @param isProjectInRequestState - Is the project in request state
 * @param isCurrentUserGroupRoltek - Is the current active user group Roltek
 * @return The active status
 */
export function generateActiveStatus(
    label: string,
    orderStatus: string,
    isProjectInRequestState: boolean,
    isCurrentUserGroupRoltek: boolean | null
) {
    if (isProjectInRequestState) {
        return i18n.t('Roltek kontrola');
    }
    if (orderStatus) {
        return isCurrentUserGroupRoltek ? orderStatus : i18n.t('Roltek produkcija');
    }
    return label ? label : '-';
}

/**
 * Generates the percentage for the progress bar based on the state
 * @param state - The current state of the project
 * @return The number of the percentage for the progress bar
 */
export function generateProgressBarPercentage(state: ProjectStates) {
    if (state === ProjectStates.LEAD) {
        return 33;
    }

    if (state === ProjectStates.REQUEST || state === ProjectStates.OFFER) {
        return 66;
    }

    return 100;
}

/**
 * Sets the newest offer as selected if no offers are currently selected
 * @param projectId - The current project id
 * @return An empty promise
 */
export async function setFirstProjectOfferAsSelectedIfNoneIsSelected(projectId: string) {
    const offers = OfferRepository.getBasicProjectOffers(projectId);

    if (offers.length <= 0) {
        return Promise.resolve();
    }

    if (!OfferRepository.isAnyOfferCurrentlySelected(projectId)) {
        await Offer.setOfferToSelected(offers[0].offerId);
    }

    return Promise.resolve();
}

/**
 * Given the project id, gets the selected offer and returns it's selling price
 * or if it's imported, its imported price
 * @param projectId - The current project id
 * @return The formatted price
 */
export function getSelectedOfferPrice(projectId: string) {
    const currencySymbol = UserRepository.getCurrentUsersCurrencySymbol();
    const selectedOffer = OfferRepository.getSelectedOffer(projectId);

    if (selectedOffer == null) {
        return '-';
    }

    const price =
        selectedOffer.state === OfferStates.IMPORTED && selectedOffer.importedPrice
            ? selectedOffer.importedPrice
            : selectedOffer?.sellingOfferPrice?.priceWithTax;

    return `${formatCurrency(price, 2, 2)} ${currencySymbol}`;
}

/**
 * Given the client, extract its address and format it
 * @param client - The client whose address needs to be extracted
 * @return The formatted address
 */
export function getFormattedClientAddress(client: Client) {
    return formatAddress(client.address);
}

/**
 * Validate client and contact person and update the project relationships if everything is valid
 * @param projectId - The current project id
 * @param clientId - The selected client id
 * @param contactPersonId - The selected contact person id
 * @return An empty promise
 */
export async function validateAndUpdateProjectClientAndContactPerson(
    projectId: string,
    clientId: string | null,
    contactPersonId: string | null | undefined
) {
    if (clientId == null || contactPersonId == null) {
        return Promise.reject(new Error(i18n.t('Klijent i kontakt osoba su obavezni')));
    }

    await Project.updateClientAndContactPerson({
        projectId,
        clientId,
        contactPersonId,
    });
    EventBus.$emit(EventBusEvents.fetchProjectFromRepository);

    return Promise.resolve();
}

export function navigateToProjectsClientDetails(clientId: string | null) {
    if (clientId == null) {
        return;
    }

    const route = router.resolve({
        name: RouteNames.editClient,
        params: { clientId },
        query: {
            initialTab: String(ClientTabs.CUSTOMER),
        },
    });

    window.open(route.href);
}

/**
 * Redirect to a project from the given response
 * @param response - The response when a new project is created
 */
export function redirectToProject(projectId: number) {
    Router.push({
        name: RouteNames.project,
        params: {
            id: String(projectId),
        },
        query: {
            initialTab: `${ProjectTabValues.Basic}`,
        },
    });
}

/**
 * Gets all the offers in a projects, sorts them by 'createdAt' and returns the name of the newest one
 * @param project - The project that contains the offers
 * @return Address
 */
export function getDeliveryAddress(project: Project) {
    if (!project?.offerRevisions) {
        return '';
    }
    const sortedOffers = sortArrayByGivenPropertyName(project.offerRevisions.slice(), 'createdAt');
    const finalIndex = sortedOffers.length - 1;
    if (sortedOffers[finalIndex]?.name === '-') {
        for (let index = finalIndex; index >= 0; index -= 1) {
            if (sortedOffers[index]?.name && sortedOffers[index].name !== '-') {
                return sortedOffers[index].deliveryAddress();
            }
        }
    }
    return null;
}
