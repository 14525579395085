
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnSorterInterface } from '@/interfaces/ColumnSorterInterface';
import { Column } from 'ant-design-vue/types/table/column';
import { Pagination } from 'ant-design-vue/types/pagination';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { getTableLocale } from '@/helpers/LocaleHelper';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import Project from '@/models/Project';
import i18n from '@/i18n';
import FloatingDeleteActions from '@/components/views/home/FloatingDeleteActions.vue';
import NextStep from '@/models/NextStep';
import ProjectStatusSwitcher from '@/components/views/project/ProjectStatusSwitcher.vue';
import CustomContextMenu from '@/components/global/popup/CustomContextMenu.vue';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import Client from '@/models/Client';

@Component({
    name: 'AppTable',

    components: { AutocompleteGroupInput, FloatingDeleteActions, ProjectStatusSwitcher, CustomContextMenu },
})
export default class AppTable extends Vue {
    @Prop({ required: true }) private readonly data!: object[];
    @Prop({ required: true }) private readonly sortedInfo!: ColumnSorterInterface | null;
    @Prop({ required: true }) private readonly columns!: Column[];
    @Prop({ required: true }) private isDeleteMode!: boolean;
    @Prop({ required: true }) private isLoading!: boolean;
    @Prop({ required: true }) private entityType!: string;
    @Prop({ required: false }) private searchMessage?: string;
    @Prop({ required: false }) private customMenu?: { display: boolean; type: string };

    private searchText = '';
    private selectedRowKeys: string[] = [];
    private isInitialLoading = true;
    private pagination = {
        total: 10,
        current: 1,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true,
        pageSize: 10,
    };
    private customSorter: ColumnSorterInterface | null = null;
    private expendedRowKeys: string[] = [];
    private showMenu = false;
    private entityInfo: { id?: string; type?: string } = { type: this.customMenu?.type };

    private get ProjectTypes() {
        return ProjectTypes;
    }

    private get locale() {
        return getTableLocale();
    }

    private get salesmen() {
        return CMSUserRepository.getAllSalesmen();
    }

    private get columnsWithActions() {
        if (this.isDeleteMode) {
            return [
                ...this.columns,
                {
                    title: '',
                    dataIndex: 'actions',
                    key: 'actions',
                    scopedSlots: { customRender: 'actions' },
                    align: 'right',
                    sortOrder: false,
                },
            ];
        }
        return this.columns;
    }

    private get rowSelection() {
        return {
            type: 'checkbox',
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
        };
    }

    private get paginationData() {
        if (this.isInitialLoading) {
            return false;
        }

        return this.pagination;
    }

    private get isRoltek(): boolean {
        return this.$store.getters['jwtData/isRoltek']
    }

    private isClientBadPayer(record: Client): boolean {
        if (!record?.clientTypes) {
            return false
        }

        if (!this.isRoltek) {
            return false;
        }
        console.log(record);
        console.log(record.clientTypes.some((type) => type.code === 'SLAB-PLAČNIK'));
        return record.clientTypes.some((type) => type.code === 'SLAB-PLAČNIK');
    }

    private onRowEvent(record: any) {
        return {
            on: {
                contextmenu: (event: any) => {
                    if (!this.customMenu?.display) {
                        return;
                    }

                    event.preventDefault();

                    const entityInfo = {
                        id: record.id,
                        coordinates: { x: event.clientX, y: event.clientY },
                    };

                    this.entityInfo = { ...this.entityInfo, ...entityInfo };

                    this.showMenu = true;

                    EventBus.$emit(EventBusEvents.toggleCustomMenu, {
                        display: this.showMenu,
                        entity: this.entityInfo,
                    });
                },
            },
        };
    }

    private handleChange(pagination: Pagination, filters: object, sorter: ColumnSorterInterface) {
        this.pagination.current = pagination.current as number;
        this.pagination.pageSize = pagination.pageSize as number;
        if (sorter.column && sorter.columnKey) {
            this.customSorter = null;
        } else if (this.customSorter !== null) {
            sorter = this.customSorter;
        }
        this.$emit('updateSortedInfo', {
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            sortColumn: sorter.column,
            sortColumnKey: sorter.columnKey,
            pageSize: pagination.pageSize,
            filters,
        });
    }

    private sortByAddress() {
        const column = this.columnsWithActions.find((c) => c.key === 'client.name');
        this.customSorter = {
            column: column as object,
            columnKey: 'client.address.city',
            field: 'client.address.city',
            order: this.customSorter ? (this.customSorter.order === 'ascend' ? 'descend' : 'ascend') : 'ascend',
        } as ColumnSorterInterface;

        this.$emit('updateSortedInfo', {
            results: this.pagination.pageSize,
            page: this.pagination.current,
            sortField: this.customSorter.field,
            sortOrder: this.customSorter.order,
            sortColumn: this.customSorter.column,
            sortColumnKey: this.customSorter.columnKey,
            pageSize: this.pagination.pageSize,
        });
    }

    private async onComplete(value: boolean, nextStepId: string) {
        try {
            await NextStep.updateCompletedState(nextStepId, value, false);
        } catch (error) {
            this.$notification.error({
                message: i18n.t('Došlo je do greške') as string,
                description: (error as Error).message,
            });
        }

        EventBus.$emit(EventBusEvents.runEntitySearch);

        this.$notification.success({
            message: i18n.t('Aktivnost je uspješno spremljena') as string,
            description: '',
        });
    }

    private onDropdownValueSelect(selectedId: string, entityId: string) {
        this.$emit('dropdownValueSelect', selectedId, entityId);
    }

    private handleSearch(selectedKeys: string[], confirm: () => void) {
        confirm();
        this.searchText = selectedKeys[0];
    }

    private handleReset(clearFilters: () => {}) {
        clearFilters();
        this.searchText = '';
    }

    private onSelectChange(selectedRowKeys: string[]) {
        this.selectedRowKeys = selectedRowKeys;
        if (this.selectedRowKeys.length > 0) {
            EventBus.$emit(EventBusEvents.updateIsDeleteMode, {
                isDeleteMode: true,
                selectedRows: this.selectedRowKeys,
            });
        } else {
            EventBus.$emit(EventBusEvents.updateIsDeleteMode, { isDeleteMode: false });
        }
    }

    private deleteSelectedEntities() {
        if (this.selectedRowKeys.length <= 0) {
            this.$notification.error({
                message: i18n.t('Došlo je do greške') as string,
                description: i18n.t('Morate odabrati barem jedan redak koji želite obrisati') as string,
            });
            return;
        }

        Promise.all(
            this.selectedRowKeys.map((entityId: string) => {
                return Project.deleteExisting(entityId);
            })
        )
            .then(() => {
                EventBus.$emit(EventBusEvents.runEntitySearch);
                this.selectedRowKeys = [];
                EventBus.$emit(EventBusEvents.updateIsDeleteMode, false);
            })
            .catch(() => {
                this.$notification.error({
                    message: i18n.t('Došlo je do greške prilikom brisanja') as string,
                    description: i18n.t('Molimo Vas da pokušate ponovo') as string,
                });
                EventBus.$emit(EventBusEvents.runEntitySearch);
            });
    }

    private onUpdatePagination({
        totalNumber,
        currentPage,
        pageSize,
    }: {
        totalNumber?: number;
        currentPage?: number;
        pageSize?: number;
    }) {
        if (pageSize != null) {
            this.pagination.pageSize = pageSize;
        }

        if (currentPage != null) {
            this.pagination.current = currentPage;
        }
        if (totalNumber != null) {
            this.pagination.total = totalNumber;
        }
    }
    private setExpandedRow(key: string) {
        const index = this.expendedRowKeys.findIndex((k) => k === key);
        if (index >= 0) {
            this.expendedRowKeys.splice(index, 1);
            return;
        }
        this.expendedRowKeys.push(key);
    }

    private hideContextMenu(e: any) {
        if (!this.customMenu?.display) {
            return;
        }

        if (!this.showMenu) {
            return;
        }

        if (e.type === 'keydown' && e.keyCode !== 27) {
            return;
        }

        const isInsideTable = e.target.closest('.co-app-table .ant-table-tbody');

        if (isInsideTable) {
            return;
        }

        this.showMenu = false;

        EventBus.$emit(EventBusEvents.toggleCustomMenu, { display: this.showMenu });
    }

    private hideContextMenuOnClick() {
        if (!this.customMenu?.display) {
            return;
        }

        if (!this.showMenu) {
            return;
        }

        this.showMenu = false;

        EventBus.$emit(EventBusEvents.toggleCustomMenu, { display: this.showMenu });
    }

    private addListenersForContextMenu() {
        document.addEventListener('click', this.hideContextMenuOnClick);
        document.addEventListener('contextmenu', this.hideContextMenu);
        document.addEventListener('keydown', this.hideContextMenu);
    }

    private removeListenersForContextMenu() {
        document.removeEventListener('click', this.hideContextMenuOnClick);
        document.removeEventListener('contextmenu', this.hideContextMenu);
        document.removeEventListener('keydown', this.hideContextMenu);
    }

    private created() {
        EventBus.$on(EventBusEvents.emitProjectPagination, this.onUpdatePagination);
        EventBus.$on(EventBusEvents.emitClientPagination, this.onUpdatePagination);
        EventBus.$on(EventBusEvents.deleteSelectedEntities, this.deleteSelectedEntities);

        this.addListenersForContextMenu();
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.emitProjectPagination, this.onUpdatePagination);
        EventBus.$off(EventBusEvents.emitClientPagination, this.onUpdatePagination);
        EventBus.$off(EventBusEvents.deleteSelectedEntities, this.deleteSelectedEntities);

        this.removeListenersForContextMenu();
    }

    @Watch('isDeleteMode')
    private onIsDeleteModeChange() {
        if (!this.isDeleteMode) {
            this.selectedRowKeys = [];
        }
    }

    @Watch('isLoading', { immediate: true })
    private onIsLoadingChange() {
        if (!this.isLoading) {
            this.isInitialLoading = false;
        }
    }
}
