
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import 'vue2-datepicker/index.css';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import ReminderOptionRepository from '@/repositories/ReminderOptionRepository';
import ChatRichTextEditor from '@/components/views/ChatView/ChatRichTextEditor.vue';
import router from '@/router';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import moment from 'moment';
import ProjectRepository from '@/repositories/ProjectRepository';

export interface ActivityFormData {
    activityType: string;
    name: string;
    date: string;
    reminderOption: string;
    assignedUser: string;
    note: string;
}

@Component({
    name: 'CreateNewEventPopup',
    components: { ChatRichTextEditor },
})
export default class CreateNewEventPopup extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private activity!: UpgradedNextStep | null;
    private validationErrors: string[] = [];
    private isCalendarLoading = false;
    private eventData: any = {
        selectedDate: null,
        eventName: null,
        project: null,
        localDateFrom: null,
        localDateTo: null,
        reminderOption: null,
        activityType: null,
        localReminderOption: null,
        eventDescription: '',
        selectedUser: null,
        completed: null,
        canEditActivity: false,
        canDeleteActivity: false,
    };
    private value1 = '';
    private repeat = '';
    private radioValue = '';
    private interval = 0;
    private day = 0;
    private weekDay = '';
    private value2 = '';
    private monthDays = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ];
    private weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    private activeErrors: string[] = [];
    private reminderOptionId = '';
    private activityTypeId = '';
    private selectedUserId = '';
    private eventRepeat = [
        {
            id: 1,
            name: 'Annually',
        },
        {
            id: 2,
            name: 'Monthly',
        },
        {
            id: 3,
            name: 'Weekly',
        },
        {
            id: 4,
            name: 'Daily',
        },
        {
            id: 5,
            name: 'Never',
        },
    ];
    private selectedTab = 'Event';
    private tabs = ['Event'];
    private project: any = null;
    private users: any = [];
    private datePickerType: 'to' | 'from' = 'from';

    private formatDateInput(event: Event) {
        const currentLocale = 'DD.MM.YYYY';
        const inputVal = (event.target as HTMLInputElement).value;
        const formats = [
            currentLocale + ' HH:mm',
            currentLocale + ' HHmm',
            currentLocale + ' H:mm',
            currentLocale + ' Hmm',
        ];
        const parsed = moment(inputVal, formats);
        if (!parsed.isValid()) {
            return;
        }
        const formatted = parsed.format();
        if (this.datePickerType === 'to') {
            this.eventData.localDateTo = formatted;
        } else if (this.datePickerType === 'from') {
            this.eventData.localDateFrom = formatted;
        }
    }

    private setEventListener(pickerType: 'to' | 'from') {
        this.datePickerType = pickerType;
        const innerCalendarInput = document.querySelector('.ant-calendar-date-input-wrap input') as HTMLInputElement;

        // Add listener to calendar input and format moment date to string
        innerCalendarInput?.addEventListener('keyup', this.formatDateInput);
    }

    private addListenerToCalendar() {
        const fromElement = document.querySelector('.date-picker__from');
        const toElement = document.querySelector('.date-picker__to');
        fromElement?.addEventListener('click', () => this.setEventListener('from'));
        toElement?.addEventListener('click', () => this.setEventListener('to'));
        EventBus.$on('setNewProjectInCalendar', this.selectProject);
    }

    private removeListenerFromCalendar() {
        const fromElement = document.querySelector('.date-picker__from');
        const toElement = document.querySelector('.date-picker__to');
        fromElement?.removeEventListener('click', () => this.setEventListener('from'));
        toElement?.removeEventListener('click', () => this.setEventListener('to'));
        EventBus.$off('setNewProjectInCalendar', this.selectProject);

        const innerCalendarInput = document.querySelector('.ant-calendar-date-input-wrap input') as HTMLInputElement;
        innerCalendarInput?.removeEventListener('keyup', this.formatDateInput);
    }

    private selectProject(projectId: string) {
        const project = ProjectRepository.getProjectById(projectId);

        if (!project) {
            return;
        }

        this.eventData.project = project;
    }

    private async created() {
        this.isCalendarLoading = true;
        await this.getAll();

        if (this.popupData.eventData) {
            this.eventData = { ...this.popupData.eventData };

            if (this.eventData.reminderOption) {
                // @ts-ignore
                this.reminderOptionId = this.eventData.reminderOption?.id;
            }

            if (this.eventData.activityType) {
                // @ts-ignore
                this.activityTypeId = this.eventData.activityType?.id;
            }

            if (this.eventData.selectedUser) {
                // @ts-ignore
                this.selectedUserId = this.eventData.selectedUser?.id;
            } else {
                this.selectedUserId = '';
            }
        }
        this.isCalendarLoading = false;
        this.addListenerToCalendar();
    }

    private beforeDestroy() {
        this.removeListenerFromCalendar();
    }

    private get activityTypes() {
        return NextStepTypeRepository.getAll();
    }

    private get activeUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private get reminderOptions() {
        return ReminderOptionRepository.getAll();
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private getReminderOption(e: string) {
        // @ts-ignore
        this.eventData.reminderOption = ReminderOptionRepository.getById(e);
    }

    private getActivityOption(e: string) {
        // @ts-ignore
        this.eventData.activityType = NextStepTypeRepository.getById(e);
    }

    private getSelectedUser(e: string) {
        // @ts-ignore
        this.eventData.selectedUser = this.users.find((item: any) => item.id === e);
    }

    private openProjectsModal() {
        EventBus.$emit(EventBusEvents.closePopup);
        EventBus.$emit(EventBusEvents.openProjectsModal, {
            popupEvent: EventBusEvents.openProjectsModal,
            data: {
                origin: 'calendar',
                originData: this.eventData,
            },
        });
    }

    private openNewProjectModal() {
        this.$store.commit('generic/setCalendarPopup', true);
        EventBus.$emit(EventBusEvents.toggleNewProjectModal, true);
    }

    private async getAll() {
        try {
            const response = (await ApiController.get(ApiRoutes.cmsUser.getAllOnlyUsers)) as AxiosResponse;

            this.users = response.data;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private navigateToProject() {
        // @ts-ignore
        if (this.eventData.project.id) {
            router.push({
                name: RouteNames.project,
                params: {
                    // @ts-ignore
                    id: this.eventData.project?.id,
                },
                query: {
                    initialTab: `${ProjectTabValues.Basic}`,
                },
            });

            EventBus.$emit(EventBusEvents.closePopup);
        } else {
            return;
        }
    }

    private handleButtonClick() {
        this.validationErrors = [];
        if (this.eventData.activityType == null) {
            this.validationErrors.push(this.$t('Activity type is required!'));
        }
        if (this.eventData.eventName == null || this.eventData.eventName === '') {
            this.validationErrors.push(this.$t('Event title is required!'));
        }
        if (this.eventData.localDateFrom == null) {
            this.validationErrors.push(this.$t('Date from is required!'));
        }
        if (this.eventData.localDateTo == null) {
            this.validationErrors.push(this.$t('Date to is required!'));
        }
        if (this.eventData.selectedUser == null) {
            this.validationErrors.push(this.$t('User is required!'));
        }
        if (this.eventData.project == null) {
            this.validationErrors.push(this.$t('Project is required!'));
        }
        if (this.validationErrors.length) {
            this.$notification.error({
                message: this.validationErrors.join('\n'),
                description: '',
            });
        } else {
            // @ts-ignore
            if (this.eventData.update) {
                this.onUpdate();
            } else {
                this.onSave();
            }
        }
    }

    private onUpdate() {
        EventBus.$emit('on-update-activity', this.eventData);
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private onSave() {
        EventBus.$emit('on-save-activity', this.eventData);
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private onDelete() {
        EventBus.$emit('on-delete-activity', this.eventData);
        EventBus.$emit(EventBusEvents.closePopup);
    }
}
