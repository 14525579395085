import { Model } from '@vuex-orm/core';
import NotificationSettingsAPI from '@/api/NotificationSettingsAPI';

export default class NotificationSettings extends Model {
    public static entity = 'notificationSettings';

    public static fields() {
        return {
            id: this.string(null),
            settings: this.attr({
                BIRTHDAY: this.boolean(false),
                CLIENT_COMMERCIALIST: this.boolean(false),
                NEW_ATTACHMENT: this.boolean(false),
                ORDER_FINISHED: this.boolean(false),
                PAYMENT: this.boolean(false),
                PROJECT_COMMENT: this.boolean(false),
                PROJECT_COMMERCIALIST: this.boolean(false),
                PROJECT_ORDERED: this.boolean(false),
                PROJECT_ORDERED_COMMERCIALIST: this.boolean(false),
                PROJECT_ORDERED_SALES: this.boolean(false),
            }),
        };
    }

    public static async getAll() {
        let notifications;

        try {
            notifications = await NotificationSettingsAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: notifications.data,
        });

        return Promise.resolve();
    }

    public static async updateSettings(payload: any) {
        let notification;

        try {
            notification = await NotificationSettingsAPI.updateSettings(payload);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: notification.data,
        });

        return Promise.resolve();
    }

    public settings!: {
        BIRTHDAY: boolean;
        CLIENT_COMMERCIALIST: boolean;
        NEW_ATTACHMENT: boolean;
        ORDER_FINISHED: boolean;
        PAYMENT: boolean;
        PROJECT_COMMENT: boolean;
        PROJECT_COMMERCIALIST: boolean;
        PROJECT_ORDERED: boolean;
        PROJECT_ORDERED_COMMERCIALIST: boolean;
        PROJECT_ORDERED_SALES: boolean;
    };
}
