import { Model } from '@vuex-orm/core';
import ProductMaterialAPI from '@/api/ProductMaterialAPI';

export default class ProductMaterial extends Model {
    public static entity = 'productMaterial';

    public static fields() {
        return {
            articleNumber: this.string(''),
            commodityGroup: this.string(''),
            matchcode: this.string(''),
            price1: this.attr(null),
            price2: this.attr(null),
            productGroup: this.string(''),
            updatedAt: this.string(''),
            images: this.attr([]),
        };
    }

    public static async getAll() {
        let allProductMaterials;

        try {
            allProductMaterials = await ProductMaterialAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({ data: allProductMaterials.data });

        return Promise.resolve();
    }

    public articleNumber!: string;
    public commodityGroup!: string;
    public matchcode!: string;
    public price1!: any[];
    public price2!: any[];
    public productGroup!: string;
    public updatedAt!: string;
    public images!: string[];
}
