import { Model } from '@vuex-orm/core';
import OfferItem from '@/models/OfferItem';
import OfferAPI from '@/api/OfferAPI';
import Pdf from '@/models/Pdf';
import Montage from '@/models/Montage';
import Address from '@/models/Address';
import { OfferService } from '@/services/OfferService';
import { OfferPriceDetail } from '@/interfaces/general/OfferPriceDetail';
import OfferRepository from '@/repositories/OfferRepository';
import PaymentType from '@/models/PaymentType';
import OfferTitle from '@/models/OfferTitle';
import SellingPriceManipulation from '@/models/SellingPriceManipulation';
import PurchasePriceManipulation from '@/models/PurchasePriceManipulation';
import OfferPrice from '@/models/OfferPrice';
import { ConfigurationOverride } from '@/interfaces/general/ConfigurationOverride';
import { PaymentDetailsOptions } from '@/interfaces/components/projectNew/PaymentDetailsOptions';
import Project from '@/models/Project';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import OrderPdf from '@/models/OrderPdf';

export default class Offer extends Model {
    [key: string]: any;

    public get offersOfferItems() {
        // @ts-ignore
        return this.offerItems;
    }

    public get offerCreatedAt() {
        // @ts-ignore
        return this.createdAt;
    }

    public get offerId() {
        // @ts-ignore
        return this.id;
    }

    public get offerName() {
        // @ts-ignore
        return this.name;
    }

    public get offerPdf() {
        // @ts-ignore
        return this.pdf;
    }

    public get offerOrderPdf() {
        // @ts-ignore
        return this.orderPdf;
    }

    public get offerMontage() {
        // @ts-ignore
        return this.montage;
    }

    public get offerOfferPriceDetails() {
        // @ts-ignore
        return this.offerPriceDetails;
    }

    public get offerPriceWithTax() {
        // @ts-ignore
        return this.priceWithTax;
    }

    public get offerTax() {
        // @ts-ignore
        return this.tax;
    }
    public get offerTaxPurchase() {
        // @ts-ignore
        return this.taxPurchase;
    }

    public get offerPrice() {
        // @ts-ignore
        return this.price;
    }

    public get offerPdfId() {
        // @ts-ignore
        return this.pdf_id;
    }

    public get offerOrderPdfId() {
        // @ts-ignore
        return this.orderPdfId;
    }

    public static entity = 'offer';

    public static fields() {
        return {
            id: this.attr(''),
            price: this.attr(''),
            priceDate: this.string(''),
            state: this.string(''),
            priceWithTax: this.number(null),
            tax: this.number(null),
            taxPurchase: this.number(null),
            offerPriceDetails: this.attr([]),
            name: this.string(''),
            originalOfferId: this.attr(null),
            createdAt: this.string(''),
            orderNotes: this.attr(''),
            updatedAt: this.string(''),
            expirationDate: this.attr(null),
            project_id: this.attr(null),
            project: this.belongsTo(Project, 'project_id'),
            offerItems: this.hasMany(OfferItem, 'offer_id'),
            pdf_id: this.attr(null),
            pdf: this.belongsTo(Pdf, 'pdf_id'),
            order_pdf_id: this.attr(null),
            orderPdf: this.belongsTo(OrderPdf, 'order_pdf_id'),
            montage: this.hasOne(Montage, 'offer_id'),
            billingAddress: this.belongsTo(Address, 'billingAddress_id'),
            deliveryAddress: this.belongsTo(Address, 'deliveryAddress_id'),
            billingAddress_id: this.attr(null),
            deliveryAddress_id: this.attr(null),
            sellingPriceManipulation_ids: this.attr([]),
            sellingPriceManipulations: this.hasManyBy(SellingPriceManipulation, 'sellingPriceManipulation_ids'),
            purchasePriceManipulation_ids: this.attr([]),
            purchasePriceManipulations: this.hasManyBy(PurchasePriceManipulation, 'purchasePriceManipulation_ids'),
            productPriceDetails: this.attr({}),
            paymentType_id: this.attr(''),
            paymentType: this.belongsTo(PaymentType, 'paymentType_id'),
            offerTitles: this.hasMany(OfferTitle, 'offer_id'),
            sellingOfferPrice_id: this.attr(null),
            sellingOfferPrice: this.belongsTo(OfferPrice, 'sellingOfferPrice_id'),
            purchaseOfferPrice_id: this.attr(null),
            purchaseOfferPrice: this.belongsTo(OfferPrice, 'purchaseOfferPrice_id'),
            configurationOverride: this.attr(null),
            assistanceRequired: this.attr(false),
            comment: this.attr(null),
            sentToClient: this.boolean(false),
            isSelected: this.attr(false),
            importedPrice: this.attr(null),
        };
    }

    public static async createNew(projectId: string, paymentTypeId: string | null) {
        let newOffer;

        try {
            newOffer = await OfferAPI.createNew(projectId, paymentTypeId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insert({
            data: {
                ...newOffer.data,
                isSelected: true,
            },
        });

        return Promise.resolve(newOffer);
    }

    public static async getById(id: string) {
        let offer;

        try {
            offer = await OfferAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: [
                'offerItems',
                'offerPrice',
                'offerItemPrice',
                'offerItems.product',
                'orderPdf',
                'pdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
                'billingAddress',
                'deliveryAddress',
            ],
        });
        return Promise.resolve(offer);
    }

    public static async copyExisting(offerId: string) {
        let newOffer;

        try {
            newOffer = await OfferAPI.copyExisting(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.unselectAllSelectedOffers();
        await this.insertOrUpdate({
            data: {
                ...newOffer.data,
                isSelected: true,
            },
        });
        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);

        return Promise.resolve(newOffer);
    }

    public static async copyExistingToNewProject(offerId: string) {
        let newProject;

        try {
            newProject = await OfferAPI.copyExistingToNewOffer(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newProject);
    }

    public static async updateAddresses(
        offerId: string,
        billingAddress: string | null,
        deliveryAddress: string | null
    ) {
        let offer;
        const offerEntity = OfferRepository.getOfferById(offerId);
        let oldBillingAddressId = null;
        let oldDeliveryAddressId = null;
        if (offerEntity) {
            oldBillingAddressId = offerEntity.billingAddress ? offerEntity.billingAddress.id : null;
            oldDeliveryAddressId = offerEntity.deliveryAddress ? offerEntity.deliveryAddress.id : null;
        }

        if (oldDeliveryAddressId) {
            await Address.delete(oldDeliveryAddressId);
            // await Address.deleteExisting(oldDeliveryAddressId);
        }

        if (oldBillingAddressId) {
            await Address.delete(oldBillingAddressId);
            // await Address.deleteExisting(oldBillingAddressId);
        }

        try {
            offer = await OfferAPI.updateExisting(offerId, billingAddress, deliveryAddress);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: [
                'offerItems',
                'offerItems.product',
                'pdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
                'billingAddress',
                'deliveryAddress',
                'country',
            ],
        });

        return Promise.resolve(offer);
    }

    public static async order(offerId: string) {
        let order;

        try {
            order = await OfferAPI.order(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: order.data,
            insertOrUpdate: [
                'offerItems',
                'offerItems.product',
                'orderPdf',
                'pdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
            ],
        });

        return Promise.resolve(order);
    }

    public static async updateSentToClient(offerId: string, state: boolean) {
        let offer;

        try {
            offer = await OfferAPI.updateSentToClient(offerId, state);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: [
                'offerItems',
                'product',
                'pdf',
                'orderPdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
                'billingAddress',
                'deliveryAddress',
                'country',
            ],
        });

        return Promise.resolve(offer);
    }

    public static async clearMontage(offerId: string) {
        const offer = OfferRepository.getOfferById(offerId);

        if (offer == null) {
            return Promise.reject(new Error('This offer does not exist'));
        }
        let response;

        try {
            response = await OfferAPI.clearMontage(offerId);
            if (offer.billingAddress != null) {
                await Address.deleteExisting(offer.billingAddress.id);
            }
            if (offer.deliveryAddress != null) {
                await Address.deleteExisting(offer.deliveryAddress.id);
            }
            if (offer.montage) {
                Montage.delete(offer.montage.getId);
            }
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: response.data,
        });

        return Promise.resolve();
    }

    public static async send(offerId: string) {
        try {
            await OfferAPI.send(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getOfferPDF(resourcePath: string) {
        return await OfferAPI.getPdf(resourcePath);
    }

    public static async updateTaxAndExpirationDateAndConfigurationOverride(
        offerId: string,
        tax: number | null,
        expirationDate: string | null,
        comment: string,
        configurationOverride?: ConfigurationOverride,
        paymentTypeId?: string | null
    ) {
        let offer;
        try {
            offer = await OfferAPI.updateTaxAndExpirationDateAndConfigurationOverride(
                offerId,
                tax,
                expirationDate,
                comment,
                configurationOverride,
                paymentTypeId
            );
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: [
                'offerItems',
                'product',
                'pdf',
                'orderPdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
                'billingAddress',
                'deliveryAddress',
                'country',
            ],
        });

        return Promise.resolve(offer);
    }

    public static async updatePaymentDetails(offerId: string, paymentDetailsOptions: PaymentDetailsOptions) {
        let offer;

        try {
            offer = await OfferAPI.updatePaymentDetails(offerId, paymentDetailsOptions);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offer.data,
        });

        return Promise.resolve(offer);
    }

    public static async updateConfigurationOverrideAndComment(
        offerId: string,
        comment: string,
        configurationOverride?: ConfigurationOverride
    ) {
        let offer;

        try {
            offer = await OfferAPI.updateCommentAndConfigurationOverride(offerId, comment, configurationOverride);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: [
                'offerItems',
                'product',
                'pdf',
                'orderPdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
                'billingAddress',
                'deliveryAddress',
                'country',
            ],
        });

        return Promise.resolve(offer);
    }

    public static async updateConfigurationOverrideCommentAndExpirationDate(
        offerId: string,
        comment: string,
        expirationDate: string | null,
        configurationOverride?: ConfigurationOverride
    ) {
        let offer;

        try {
            offer = await OfferAPI.updateConfigurationOverrideCommentAndExpirationDate(
                offerId,
                comment,
                expirationDate,
                configurationOverride
            );
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: [
                'offerItems',
                'product',
                'pdf',
                'orderPdf',
                'montage',
                'offerItems.material',
                'offerItems.material.image',
                'billingAddress',
                'deliveryAddress',
                'country',
            ],
        });

        return Promise.resolve(offer);
    }

    public static async updateOrderNotes(offerId: string, orderNotes: string | null) {
        let offer;

        try {
            offer = await OfferAPI.updateOrderNotes(offerId, orderNotes);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offer.data,
        });

        return Promise.resolve(offer);
    }

    public static async updatePaymentType(offerId: string, paymentType: string) {
        let offer;
        try {
            offer = await OfferAPI.updatePaymentType(offerId, paymentType);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: offer.data,
        });
        return Promise.resolve();
    }

    public static async requestAssistance(offerId: string) {
        try {
            await OfferAPI.requestAssistance(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async cancelAssistance(offerId: string) {
        try {
            await OfferAPI.cancelAssistance(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async finishAssistance(offerId: string) {
        try {
            await OfferAPI.finishAssistance(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getOffersByPaymentType(paymentTypeId: string) {
        let offer;

        try {
            offer = await OfferAPI.getOffersByPaymentType(paymentTypeId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer.data);
    }

    public static async getForOverviewTab(id: string) {
        let offer;

        try {
            offer = await OfferAPI.getForOverviewTab(id);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: ['pdf', 'offerPrice', 'offerTitle', 'documentName'],
        });

        return Promise.resolve(offer);
    }

    public static async getForOffersTab(id: string) {
        let offer;

        try {
            offer = await OfferAPI.getForOffersTab(id);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offer.data,
        });

        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
        return Promise.resolve(offer);
    }

    public static async deleteExisting(offerId: string) {
        try {
            await OfferAPI.delete(offerId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Offer.delete(offerId);
        return Promise.resolve();
    }

    public static async deleteDeliveryAddress(offerId: string): Promise<void> {
        let offer;

        try {
            offer = await OfferAPI.deleteDeliveryAddress(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: ['address', 'country'],
        });

        return Promise.resolve();
    }

    public static async deleteBillingAddress(offerId: string): Promise<void> {
        let offer;

        try {
            offer = await OfferAPI.deleteBillingAddress(offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offer.data,
            insertOrUpdate: ['address', 'country'],
        });

        return Promise.resolve();
    }

    public static async setOfferToSelected(offerId: string) {
        await this.unselectAllSelectedOffers();

        await this.update({
            where: offerId,
            data: {
                isSelected: true,
            },
        });

        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
    }

    public static async unselectAllSelectedOffers() {
        const selectedOffers = OfferRepository.getAllSelectedOffers();

        return Promise.all(
            selectedOffers.map((offer) =>
                this.update({
                    data: {
                        id: offer.offerId,
                        isSelected: false,
                    },
                })
            )
        );
    }

    public static async unlockOffer(offerId: string) {
        try {
            await OfferAPI.unlockOffer(offerId);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public static hasOriginalOffer(offer: Offer | null) {
        return OfferService.hasOriginalOffer(offer);
    }

    public static hasPdfRelationship(offer: Offer | null) {
        return OfferService.hasPdfRelationship(offer);
    }
    public id!: string;
    public price!: number;
    public priceDate!: string;
    public state!: string;
    // tslint:disable-next-line:variable-name
    public project_id!: string;
    public updatedAt!: string;
    public createdAt!: string;
    public offerItems!: OfferItem[];
    public priceWithTax!: number;
    public tax!: number;
    public taxPurchase!: number;
    public offerPriceDetails!: OfferPriceDetail[];
    public name!: string;
    public deliveryAddress!: Address | null;
    public billingAddress!: Address | null;
    public sellingPriceManipulations!: SellingPriceManipulation[];
    public purchasePriceManipulations!: PurchasePriceManipulation[];
    public offerTitles!: OfferTitle[];
    public productPriceDetails!: any;
    public originalOfferId!: number | null;
    public pdf!: Pdf | null;
    public orderPdf!: OrderPdf | null;
    public montage!: Montage | null;
    public paymentType!: PaymentType | null;
    public expirationDate!: string | null;
    public orderNotes!: string | null;
    public sellingOfferPrice!: OfferPrice;
    public purchaseOfferPrice!: OfferPrice;
    public configurationOverride!: ConfigurationOverride;
    public assistanceRequired!: boolean;
    public comment!: string;
    public sentToClient!: boolean;
    public importedPrice!: number | null;
    public isSelected!: boolean;
}
