export enum RouteNames {
    login = 'login',
    home = 'home',
    activities = 'activities',
    configurator = 'product',
    error = 'error',
    client = 'client',
    editClient = 'editClient',
    createClient = 'createClient',
    cmsUsers = 'cmsUsers',
    editCmsUser = 'editCmsUser',
    createCmsUser = 'createCmsUser',
    translationList = 'translationList',
    translation = 'translation',
    settings = 'settings',
    userSettings = 'userSettings',
    cmsChecklistFields = 'cmsChecklistFields',
    cmsSingleChecklistField = 'cmsSingleChecklistField',
    productDetails = 'productDetails',
    editProductDetails = 'editProductDetails',
    cmsItemList = 'cmsItemList',
    cmsShow = 'cmsShow',
    cmsIndex = 'cmsIndex',
    cmsStatistics = 'cmsStatistics',
    cmsNews = 'cmsNews',
    statisticsDashboard = 'statisticsDashboard',
    dashboards = 'dashboards',
    resetPassword = 'resetPassword',
    forgotPassword = 'forgotPassword',
    customSearch = 'customSearch',
    chat = 'chat',
    manualAPI = 'manualAPI',
    workflow = 'workflow',
    workflowTemplate = 'workflowTemplate',
    project = 'project',
    phoneProject = 'phoneProject',
    contentSubcategory = 'contentSubcategory',
    cmsContent = 'cmsContent',
    calculator = 'calculator',
    calculatorProduct = 'calculatorProduct',
    newclient = 'newclient',
    technicalDocumentation = 'technical-documentation',
    schedulesResources = 'schedulesResources',
    calendar = 'calendar',
    automaticEvents = 'automaticEvents',
    newProductSystemWizard = 'newProductSystemWizard',
    clientNewInquiry = 'clientNewInquiry',
    warrantyWarnings = 'warrantyWarnings',
    register = 'register',
    clientScreen = 'clientScreen',
    productVersions = 'productVersions',
    notifications = 'notifications',
}
